import { useFormContext } from 'react-hook-form';
import { updatePresentEqInfo } from '@/services/TaskService';
import { Button, Stack, Typography } from '@mui/material';
import FormPresentPu from './FormPresentPu';
import FromPresentTransformer from './FromPresentTransformer';
import FromPresentTransformerVoltage from './FromPresentTransformerVoltage';
import { color } from '@/styles/mixins';
import { PresentEquipments } from '@/dto/taskmap/task/TaskResultDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useExistingEquipment } from './store';
import shallow from 'zustand/shallow';
import { useCatalog } from '@/hooks/CatalogHook';

interface FormForExistingEquipmentProps {
  id: string;
  existingEquipmentUrl: string;
  closeModal: () => void;
}

const FormForExistingEquipment = ({
  closeModal,
  id,
  existingEquipmentUrl,
}: FormForExistingEquipmentProps) => {
  const { getExistingEquipmentByKey, setExistingEquipment } = useExistingEquipment(
    (store) => ({
      setExistingEquipment: store.setExistingEquipment,
      getExistingEquipmentByKey: store.getExistingEquipmentByKey,
    }),
    shallow
  );
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const processKtn = (ktn: string) => {
    if (!ktn) return [];
    const catalog = getCatalogMapWithErr('ktnType').get(ktn);
    if (catalog) {
      const arr = catalog.split('/');
      return arr;
    }
    return ktn.split('/');
  };

  const { catchError } = useActionLog();
  const { setIsInProgress, isInProgress } = useScreenHoldHook();
  const existingEquipment = getExistingEquipmentByKey(id);

  const presentPu = (existingEquipment && existingEquipment?.presentEquipments?.presentPu) || null;
  const presentTransformer =
    (existingEquipment && existingEquipment?.presentEquipments?.presentTransformer) || null;
  const presentTransformerVoltage =
    (existingEquipment && existingEquipment?.presentEquipments?.presentTransformerVoltage) || null;

  const { handleSubmit } = useFormContext();
  const handleForm = (data: PresentEquipments) => {
    const modifiedData: Partial<PresentEquipments> = {};
    modifiedData.presentPu = presentPu ? data['presentPu'] : null;
    modifiedData.presentTransformer = presentTransformer ? data['presentTransformer'] : null;

    modifiedData.presentTransformerVoltage = presentTransformerVoltage
      ? data['presentTransformerVoltage']
      : null;
    if (
      modifiedData.presentTransformerVoltage?.ktn &&
      typeof modifiedData.presentTransformerVoltage.ktn === 'string'
    ) {
      modifiedData.presentTransformerVoltage.ktn = {
        n1: processKtn(modifiedData.presentTransformerVoltage.ktn)[0],
        n2: processKtn(modifiedData.presentTransformerVoltage.ktn)[1],
      };
    }

    setIsInProgress(true);
    updatePresentEqInfo(id, { presentEquipments: modifiedData }, existingEquipmentUrl)
      .then(({ data }) => {
        setExistingEquipment(data);
        closeModal();
      })
      .catch((err) =>
        catchError('Ошибка при обновление информации о существующеем оборудование', err)
      )
      .finally(() => setIsInProgress(false));
  };

  return (
    <Stack>
      <Typography
        textAlign={'center'}
        variant='h5'
        color={color('trueBlack')}
      >
        Внесите изменения
      </Typography>
      <Stack
        mt={3}
        alignItems={'center'}
      >
        <form onSubmit={handleSubmit(handleForm)}>
          {/* present PU  separated component */}
          {presentPu && (
            <FormPresentPu
              keyString={'presentPu'}
              formData={presentPu}
            />
          )}
          {/* present Transformer  separated component */}
          {presentTransformer && (
            <FromPresentTransformer
              keyString={`presentTransformer`}
              formData={presentTransformer}
            />
          )}
          {/* present TransformerVoltage  separated component */}
          {presentTransformerVoltage && (
            <FromPresentTransformerVoltage
              keyString={'presentTransformerVoltage'}
              formData={presentTransformerVoltage}
            />
          )}
          <Stack
            flexDirection={'row'}
            mt={2}
            justifyContent={'space-around'}
          >
            <Button
              disabled={isInProgress}
              type='submit'
            >
              сохранить
            </Button>
            <Button onClick={closeModal}>отмена</Button>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
};

export default FormForExistingEquipment;
