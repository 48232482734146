import {
  PresentEquipments,
  PresentPu,
  PresentTransformer,
  PresentTransformerVoltage,
} from '@/dto/taskmap/task/TaskResultDto';

export const lookUpExistingEquipment = {
  presentPu: 'Существующий ПУ',
  presentTransformer: 'Существующий ТТ',
  presentTransformerVoltage: 'Существующий ТН',
};

export function filterObjectsWithValues(
  presentEquipments: PresentEquipments
): { key: string; value: PresentPu | PresentTransformer | PresentTransformerVoltage }[] {
  return Object.entries(presentEquipments).reduce((result, [key, value]) => {
    if (
      value &&
      typeof value === 'object' &&
      Object.values(value).some((v) => v !== null && v !== undefined)
    ) {
      result.push({ key, value });
    }
    return result;
  }, [] as { key: string; value: PresentPu | PresentTransformer | PresentTransformerVoltage }[]);
}

export type KeyString = keyof typeof lookUpExistingEquipment;
export const getKtnValue = (ktn: string | { n1?: string; n2?: string } | undefined): string => {
  if (typeof ktn === 'string') {
    return ktn;
  } else if (ktn?.n1 && ktn?.n2) {
    return `${ktn.n1}/${ktn.n2}`;
  }
  return '';
};
export const getKttOrKtnValue = (
  value: PresentTransformer | PresentTransformerVoltage | PresentPu
): string | null => {
  if ('ktt' in value && value.ktt) {
    return value.ktt;
  }
  if ('ktn' in value && typeof value.ktn === 'object' && value.ktn?.n1 && value.ktn?.n2) {
    return `${value.ktn.n1}/${value.ktn.n2}`;
  }

  return null;
};
