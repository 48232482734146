import {
  ArcData,
  DynamicStatisticItem,
  NamedStatisticInvestShowInfo,
  StatisticBudgetColumnItem,
  StatisticBudgetColumnItemSegment,
  StatisticBudgetItemShowInfo,
  StatisticInvestProgramItem,
} from './types';
import { ActiveElement, Chart, ChartEvent, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import * as d3 from 'd3';

export const energyCompanyOptions = [
  { value: 'c0caeb59-e060-eb31-240d-7b82daa12bf7', label: 'МосЭнергоСбыт' },
];

// const contractListResponse = useAllContractQuery();
export const contractListResponse = [
  { value: '1bc81a82-9c91-80fe-9867-ddc38d7b291f', label: 'ИСУ МОСКВА 2024' },
  {
    value: '223cc825-f997-eafa-c3ca-778d1648cc29',
    label: 'ИСУ Опорная сеть Московская область 2024',
  },
  { value: '2aeb8e2f-aa17-b7cc-e809-1113df1f54ba', label: 'ИСУ МО 2024' },
  { value: '836c1e83-6231-0a0f-616a-d728f4e10f0e', label: 'ОДПУ Москва 2024' },
  { value: 'b03cc7ed-c3b6-c740-6554-62e09c95f13c', label: 'ОДПУ МО 2024' },
  { value: 'f7e1e13f-4bfe-9843-6acf-5de9bd12f224', label: 'ИСУ Опорная сеть Москва 2024' },
];

export const doughnutSectorOptions: ChartOptions<'doughnut'> = {
  layout: {
    padding: {
      left: 150,
      right: 150,
    },
  },
  spacing: 5,
};

export const filterShowInfo = (
  showInfo?: NamedStatisticInvestShowInfo,
  withNartis?: boolean
): NamedStatisticInvestShowInfo | undefined => {
  if (!showInfo) {
    return undefined;
  }
  if (withNartis) {
    // Фильтруем ключи с пустыми массивами
    return Object.fromEntries(
      Object.entries(showInfo).filter(([_, value]) => value && value.length > 0)
    );
  }

  // Убираем ключ "nartis" и фильтруем пустые массивы
  const { nartis, ...rest } = showInfo;
  return Object.fromEntries(Object.entries(rest).filter(([_, value]) => value && value.length > 0));
};

export const createTableShowInfo = (
  context: any,
  d: ArcData,
  i: number,
  outerArc: d3.Arc<any, d3.PieArcDatum<StatisticInvestProgramItem>>,
  radius: number,
  shiftAmount: number,
  withNartis?: boolean
) => {
  const pos = outerArc.centroid(d);

  const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;

  pos[0] = radius * 1.45 * (midangle < Math.PI ? 1 : -1);
  pos[1] -= 15;

  const filteredShowInfo = filterShowInfo(d.data?.showInfo, withNartis);
  if (!filteredShowInfo) return;

  const tableContent = `
   <div>
  <div class='doughnut-tooltip-header'>
    <div>${d.data?.title}</div>
    <div>${d.data?.totalCount}</div>
  </div>
  <div class='doughnut-tooltip-body'>
    <table>
      <tbody class='tooltip-table-body'>
        ${Object.values(filteredShowInfo)
          .map(
            (items, index) => `
            ${
              index !== 0
                ? `
              <tr>
                <td style="height: 10px;"></td>
              </tr>`
                : ''
            }
            ${items
              ?.map(
                (item) => `
              <tr>
                <td>${item.title}</td>
                <td>${item.percentage}%</td>
                <td>${item?.amount}</td>
                <td>ШТ</td>
              </tr>`
              )
              .join('')}
          `
          )
          .join('')}
      </tbody>
    </table>
  </div>
</div>
    `;

  // Добавляем временный foreignObject
  const foreignObject = d3
    .select(context)
    .append('foreignObject')
    .attr('x', pos[0])
    .attr('y', pos[1])
    .attr('width', 1) // Минимальная начальная ширина
    .attr('height', 1) // Минимальная начальная высота
    .html(tableContent);

  // Извлекаем HTML-элемент внутри foreignObject
  const node = foreignObject.node() as Element;
  const contentNode = node.querySelector('div') as HTMLElement; // Ищем первый <div>

  if (!contentNode) return;

  // Заставляем HTML-контент расширяться по ширине
  contentNode.style.display = 'inline-block';
  contentNode.style.whiteSpace = 'nowrap';

  // Получаем размеры контента
  const bbox = contentNode.getBoundingClientRect();

  // Определяем процентное покрытие сегмента
  const percent = ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100;

  // Корректируем позицию с учетом i и shiftAmount
  pos[0] -= bbox.width / 2;

  if (percent < 50) {
    pos[1] += shiftAmount * i - bbox.height + 30;
  } else {
    pos[1] -= bbox.height - 10;
  }

  // Обновляем позицию и размеры
  foreignObject
    .attr('x', pos[0])
    .attr('y', pos[1])
    .attr('width', bbox.width)
    .attr('height', bbox.height);
};

export const createLabels = (
  context: any,
  d: ArcData,
  i: number,
  outerArc: d3.Arc<any, d3.PieArcDatum<StatisticInvestProgramItem>>,
  radius: number,
  shiftAmount: number
) => {
  const textElement = d3
    .select(context)
    .append('text')
    .attr('class', () => `hide-${d.index} hiddable`)
    .text(d.data.title)
    .style('font-weight', '500')
    .style('fill', '#096FB7')
    .attr('text-anchor', function () {
      const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
      return midangle < Math.PI ? 'start' : 'end';
    });

  const node = textElement.node();
  let bbox;
  if (node !== null) {
    bbox = node.getBBox();
  }

  const pos = outerArc.centroid(d);
  const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
  pos[0] = radius * 1.45 * (midangle < Math.PI ? 1 : -1);

  if (!bbox) {
    return;
  }

  const percent = ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100;
  if (percent < 50) {
    pos[0] += bbox.width * (midangle < Math.PI ? -1 : 1);
    pos[1] += shiftAmount * i - bbox.height + 9;
  } else {
    pos[0] -= bbox.width * (midangle < Math.PI ? 1 : -1);
    pos[1] -= bbox.height - 9;
  }

  textElement.attr('transform', 'translate(' + pos + ')');

  const rect = d3
    .select(context)
    .insert('rect', 'text')
    .attr('x', midangle < Math.PI ? pos[0] - 5 : pos[0] - bbox.width - 5)
    .attr('y', pos[1] - bbox.height)
    .attr('width', bbox.width + 10)
    .attr('height', bbox.height + 10)
    .attr('fill', 'white')
    .attr('stroke', '#096FB7')
    .attr('stroke-width', 1)
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('class', () => `hide-${d.index} hiddable`);

  textElement.attr('transform', 'translate(' + pos + ')');
};

export const backgroundColorDoughnutSector = (ctx: any, color: string, index: number) => {
  const backgroundColors: string[] = [];
  ctx.dataset.data.forEach((item: number, idx: number) => {
    if (idx === index) {
      backgroundColors.push(color);
    } else {
      backgroundColors.push('transparent');
    }
  });
  return backgroundColors;
};

export const getCutoutValue = (data: StatisticInvestProgramItem, index: number) => {
  let cutout;
  if (((data?.segments[index] * 100) / data?.count) * 0.6 < 5) {
    cutout = 40 + (index + 1) * 5;
  } else {
    cutout = 40 + ((data?.segments[index] * 100) / data?.count) * 0.6;
  }
  return cutout;
};

export const dataSegment = (
  data: StatisticInvestProgramItem,
  dataSectors: number[],
  index: number,
  indexSector: number
): any => {
  return {
    labels: [],
    datasets: [
      {
        data: dataSectors,
        backgroundColor: (ctx: any) =>
          backgroundColorDoughnutSector(ctx, backgroundColors[index], indexSector),
        borderColor: 'transparent',
        borderWidth: 1,
        borderRadius: { outerStart: 10, outerEnd: 10, innerStart: 0, innerRight: 0 },
        cutout: `${getCutoutValue(data, index)}%`,
      },
    ],
  };
};

export const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('#tooltipEl');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'tooltipEl';
    tooltipEl.style.background = 'transparent';
    tooltipEl.style.color = '#000000';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const tooltipBody = document.createElement('div');
    tooltipBody.id = 'tooltipBody';
    tooltipBody.style.margin = '0px';

    tooltipEl.appendChild(tooltipBody);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: any) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const tooltipBodyRoot = tooltipEl.querySelector('div');
  while (tooltipBodyRoot.firstChild) {
    tooltipBodyRoot.firstChild.remove();
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

  return tooltipEl;
};

export const FILTERS_LABELS = {
  contract: 'Контракт',
  energyCompany: 'Сбыт',
};

export const backgroundColors = ['#547483', '#344851'];

export const getDataSectors = (data: StatisticInvestProgramItem[]) => {
  const dataSectors = data.map((item) => item.count);

  const sum = dataSectors.reduce(function (sum, current) {
    return sum + current;
  }, 0);

  data.forEach((item, index) => {
    if ((item.count * 100) / sum < 1) {
      dataSectors[index] = 1;
    } else {
      dataSectors[index] = (item.count * 100) / sum;
    }
  });
  return dataSectors;
};

export const dataSectorDoughnut = (
  data: number[],
  backgroundColor: string,
  cutout: string,
  hoverBackgroundColor: string
) => {
  return {
    labels: [],
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColor,
        borderColor: 'transparent',
        borderWidth: 1,
        borderRadius: { outerStart: 10, outerEnd: 10, innerStart: 0, innerRight: 0 },
        cutout: cutout,
        hoverBackgroundColor: hoverBackgroundColor,
      },
    ],
  };
};

export const chartTooltip = (
  tooltipEl: HTMLElement,
  id: string,
  data: any,
  headerColor?: string
) => {
  tooltipEl.innerHTML = `
   <div class='line-tooltip'>
        ${
          id === 'show-info-line'
            ? `<div id='line-tooltip-header' style='background-color: ${headerColor};'>
                 <div>${data.count}</div>
                </div>`
            : ``
        }
        <div>
            <canvas id=${id}  class="show-info ${
    headerColor ? 'no-top-border-radius' : ''
  }" width='100%' height='100%'></canvas>
        </div>
    </div>
    `;
  const canvas = document.getElementById(id) as HTMLCanvasElement;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  return new Chart(ctx, {
    type: 'bar',
    data: {
      labels: data?.showInfo?.map((item: StatisticBudgetItemShowInfo) => item.title),
      datasets: [
        {
          label: '',
          data: data?.showInfo?.map((item: StatisticBudgetItemShowInfo) => item.count),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
          barThickness: 17,
          backgroundColor: ['#E1265B', '#EB7B9B', '#E1265B40'],
        },
      ],
    },
    plugins: [ChartDataLabels],
    options: {
      layout: {
        padding: {
          left: 10,
          right: 45,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          labels: {
            title: {
              color: '#000000',
              font: {
                weight: 400,
              },
            },
          },
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          ticks: {
            color: '#000000',
          },
          grid: {
            display: false,
          },
        },
      },
    },
  });
};

export const createGradient = (context: any, colors: string[]) => {
  const chart = context.chart;
  const { ctx, chartArea } = chart;

  if (!chartArea) {
    return null;
  }

  const { dataIndex } = context;
  const bar = chart.getDatasetMeta(0).data[dataIndex];

  if (!bar || !bar.x || !bar.width) {
    return null;
  }

  const gradient = ctx.createLinearGradient(bar.x - bar.width / 2, 0, bar.x + bar.width / 2, 0);

  gradient.addColorStop(0, colors[0]);
  gradient.addColorStop(1, colors[1]);

  return gradient;
};

export const backgroundColorGradient = [
  ['transparent', 'transparent'],
  ['#A3CCE9', '#5C7383'],
  ['#588ABE', '#294058'],
];

export const borderColorGradient = [
  ['#A3CCE9', '#5C7383'],
  ['transparent', 'transparent'],
  ['transparent', 'transparent'],
];

// export const generateDataBar = (data?: StatisticBudgetColumnItem[]): any => {
//   const dataChart = {
//     labels: data?.map(
//       (item) => contractListResponse.find((it) => it.value === item.catalogId)?.label
//     ),
//     datasets:
//       data && data[0]?.segmentParts
//         ? data[0]?.segmentParts
//           ?.map((it: StatisticBudgetColumnItemSegment, idx: number) => {
//             const isLastSegment = idx === 0;
//             return {
//               label: '',
//               data: data?.map((item) => {
//                 const segments = [...item.segmentParts].sort((a, b) => b.count - a.count);

//                 const transformValue = (value: number) => {
//                   if ((value * 100) / segments[0].count < 5) {
//                     value = segments[0].count * 0.05;
//                   }
//                   return value;
//                 };

//                 return segments[idx + 1]
//                   ? transformValue(segments[idx].count - segments[idx + 1]?.count)
//                   : transformValue(segments[idx].count);
//               }),
//               backgroundColor: (context: any) =>
//                 createGradient(context, backgroundColorGradient[idx]),
//               borderColor: (context: any) => createGradient(context, borderColorGradient[idx]),
//               borderWidth: isLastSegment && 1,
//               barThickness: 90,
//               stack: 'Stack 0',
//               datalabels: {
//                 formatter: function (value: number, context: any) {
//                   return data[context.dataIndex]?.segmentParts
//                     .sort((a, b) => b.count - a.count)
//                     .reverse()[context.datasetIndex].count;
//                 },
//                 anchor: isLastSegment ? 'end' : 'center',
//                 align: isLastSegment ? 'top' : 'center',
//                 font: {
//                   weight: 'bold',
//                 },
//                 labels: {
//                   title: {
//                     color: isLastSegment ? '#000000' : '#FFFFFF',
//                   },
//                 },
//               },
//             };
//           })
//           ?.reverse()
//         : [],
//   };

//   return dataChart;
// };

const BOLD_TICK = {
  font: {
    size: 14,
    weight: 'bold', // Устанавливаем жирный текст
  },
  color: 'black', // Дополнительно цвет текста
};

export const getBarOptions = (
  setSelectedColumn: (index: number | null) => void,
  data?: StatisticBudgetColumnItem[]
): ChartOptions<'bar'> => {
  return {
    layout: {
      padding: {
        top: 20,
      },
    },
    onClick: (event: any, elements: any) => {
      const index = elements[0].index;
      if (elements.length) {
        setSelectedColumn(index);
      } else {
        setSelectedColumn(null);
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        font: {
          weight: 'bold',
        },
        labels: {
          title: {
            color: '#FFFFFF',
          },
        },
      },
      tooltip: {
        enabled: false,
        external: (context: any) => {
          const { chart, tooltip } = context;

          const { dataPoints } = tooltip;
          const index = dataPoints[0].dataIndex;
          const datasetIndex = dataPoints[0].datasetIndex;

          const infoBar = data?.find((item, idx) => idx === index);

          const dataTooltip = infoBar?.segmentParts
            .sort((a, b) => a.count - b.count)
            .find((item, idx) => idx === datasetIndex);

          const tooltipEl = externalTooltipHandler(context);

          if (!tooltipEl) {
            return;
          }

          if (!dataTooltip?.showInfo) {
            return;
          }

          chartTooltip(tooltipEl, 'show-info-column', dataTooltip);
        },
      },
    },
    scales: {
      x: {
        ticks: {
          ...BOLD_TICK,

          //   Перенос лейблов по оси Х
          callback: function (value) {
            const label = this.getLabelForValue(value as number);

            return breakLineIfNumber(label);
          },
        },
      },
      y: { ticks: BOLD_TICK },
    },
  };
};

export const colorsLine = ['#023E72', '#096FB7', '#189DDE', '#56CCF2'];
export enum DYNAMIC_SWITCH_LABELS {
  NARTIS = 'НАРТИС',
}
export const generateDataLine = (data: DynamicStatisticItem[], withNartis?: boolean) => {
  const LOWER_CASE_NARTIS = DYNAMIC_SWITCH_LABELS.NARTIS.toLowerCase();
  return {
    labels: data[0]?.data.map((item) => moment(item.date).format('DD-MM-yyyy')),
    datasets: data
      .filter((item) => withNartis || item.title.toLowerCase() !== LOWER_CASE_NARTIS)
      .map((item, idx) => {
        return {
          label: item.title,
          data: item.data.map((item) => item.count),
          borderColor: colorsLine[idx],
          pointRadius: 0,
          borderWidth: 5,
          ...(withNartis &&
            item.title.toLowerCase() === LOWER_CASE_NARTIS && { borderDash: [15, 15] }),
        };
      }),
  };
};

export const getOptionsLineChart = (data: DynamicStatisticItem[]): ChartOptions<'line'> => {
  return {
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context: any) => {
          const { chart, tooltip } = context;

          const lineColor = context?.tooltip?.dataPoints[0]?.dataset?.borderColor;

          const { dataPoints } = tooltip;
          const index = dataPoints[0].dataIndex;

          const datasetIndex = dataPoints[0].datasetIndex;

          const infoLine = data.find((item, idx) => idx === datasetIndex);

          const dataTooltip = infoLine?.data.find((item, idx) => idx === index);

          const tooltipEl = externalTooltipHandler(context);

          if (!tooltipEl) {
            return;
          }

          if (!dataTooltip?.showInfo) {
            return;
          }

          chartTooltip(tooltipEl, 'show-info-line', dataTooltip, lineColor as string);
        },
        mode: 'point',
      },
      datalabels: {
        formatter: function (value: number, context: any) {
          if (context.dataIndex + 1 !== data[0].data.length) {
            return null;
          }
          return data[context.datasetIndex]?.title;
        },
        anchor: 'end',
        align: 'left',
        color: '#FFFFFF',
        borderRadius: 4,
        padding: { top: 4, bottom: 4, left: 16, right: 16 },
        borderWidth: 1,
        backgroundColor: (context: any) => {
          return context.dataset.borderColor;
        },
        font: {
          size: 10,
          weight: 500,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          ...BOLD_TICK,
        },
      },
      y: { ticks: BOLD_TICK },
    },
  };
};

export const timePeriod = [
  {
    title: 'ДЕНЬ',
    name: 'day',
  },
  {
    title: 'НЕДЕЛЯ',
    name: 'week',
  },
  {
    title: 'МЕСЯЦ',
    name: 'month',
  },
];

export const EQUIPMENT_DATA = [
  {
    title: 'РОУТЕРЫ',
    name: 'router',
    segments: [45000, 6500, 10000],
    count: 100000,
    showInfo: {
      default: [
        {
          title: 'МОНТАЖ',
          percentage: 63,
          amount: 45000,
        },
        {
          title: 'ПОДПИСАН СМР',
          percentage: 20,
          amount: 6500,
        },
        {
          title: 'ПОДПИСАН ПНР',
          percentage: 17,
          amount: 20000,
        },
      ],
      nartis: [
        {
          title: '1',
          percentage: 3,
          amount: 10,
        },
        {
          title: '2',
          percentage: 20,
          amount: 100,
        },
      ],
    },
  },

  {
    title: '3Ф',
    name: '3f',
    segments: [165000, 101000],
    count: 300000,
    showInfo: {
      default: [
        {
          title: 'МОНТАЖ',
          percentage: 63,
          amount: 165000,
        },
        {
          title: 'ПОДПИСАН СМР',
          percentage: 20,
          amount: 5500,
        },
        {
          title: 'ПОДПИСАН ПНР',
          percentage: 17,
          amount: 101000,
        },
      ],
    },
  },
];

export const DATA_COLUMNS = [
  {
    catalogId: 'f7e1e13f-4bfe-9843-6acf-5de9bd12f224',
    segmentParts: {
      default: [
        {
          count: 1000,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 14834,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 40201,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 32022,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 7417,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 20100,
              },
            ],
          },
        },
        {
          count: 67811,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 41490,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 11327,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 14994,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 20745,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 5663,
              },
            ],
          },
        },
        {
          count: 76885,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 27081,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 31988,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 17816,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 13540,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 15994,
              },
            ],
          },
        },
      ],
      nartis: [
        {
          count: 16670,
        },
      ],
    },
  },
  {
    catalogId: 'f7e1e13f-4bfe-9843-6acf-5de9bd12f2245',
    segmentParts: {
      default: [
        {
          count: 87057,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 14834,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 40201,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 32022,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 7417,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 20100,
              },
            ],
          },
        },
        {
          count: 67811,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 41490,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 11327,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 14994,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 20745,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 5663,
              },
            ],
          },
        },
        {
          count: 76885,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 27081,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 31988,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 17816,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 13540,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 15994,
              },
            ],
          },
        },
      ],
      nartis: [
        {
          count: 16670,
        },
      ],
    },
  },
  {
    catalogId: 'f7e1e13f-4bfe-9843-6acf-5de9bd12f2244',
    segmentParts: {
      default: [
        {
          count: 87057,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 14834,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 40201,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 32022,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 7417,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 20100,
              },
            ],
          },
        },
        {
          count: 67811,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 41490,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 11327,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 14994,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 20745,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 5663,
              },
            ],
          },
        },
        {
          count: 76885,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 27081,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 31988,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 17816,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 13540,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 15994,
              },
            ],
          },
        },
      ],
      nartis: [
        {
          count: 16670,
        },
      ],
    },
  },
  {
    catalogId: 'f7e1e13f-4bfe-9843-6acf-5de9bd12f2243',
    segmentParts: {
      default: [
        {
          count: 87057,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 14834,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 40201,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 32022,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 7417,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 20100,
              },
            ],
          },
        },
        {
          count: 67811,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 41490,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 11327,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 14994,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 20745,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 5663,
              },
            ],
          },
        },
        {
          count: 76885,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 27081,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 31988,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 17816,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 13540,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 15994,
              },
            ],
          },
        },
      ],
      nartis: [
        {
          count: 16670,
        },
      ],
    },
  },
  {
    catalogId: 'f7e1e13f-4bfe-9843-6acf-5de9bd12f2242',
    segmentParts: {
      default: [
        {
          count: 87057,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 14834,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 40201,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 32022,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 7417,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 20100,
              },
            ],
          },
        },
        {
          count: 67811,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 41490,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 11327,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 14994,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 20745,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 5663,
              },
            ],
          },
        },
        {
          count: 76885,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 27081,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 31988,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 17816,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 13540,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 15994,
              },
            ],
          },
        },
      ],
      nartis: [
        {
          count: 16670,
        },
      ],
    },
  },
  {
    catalogId: 'f7e1e13f-4bfe-9843-6acf-5de9bd12f2241',
    segmentParts: {
      default: [
        {
          count: 87057,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 14834,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 40201,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 32022,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 7417,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 20100,
              },
            ],
          },
        },
        {
          count: 67811,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 41490,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 11327,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 14994,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 20745,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 5663,
              },
            ],
          },
        },
        {
          count: 76885,
          showInfo: {
            default: [
              {
                title: 'КВАРТИРА 1Ф',
                count: 27081,
              },
              {
                title: 'ЛЕСТНИЦА 1Ф',
                count: 31988,
              },
              {
                title: 'ХОЛЛ 1Ф',
                count: 17816,
              },
            ],
            nartis: [
              {
                title: 'НАРТИС',
                count: 13540,
              },
              {
                title: 'ПРОЧИЕ ПУ',
                count: 15994,
              },
            ],
          },
        },
      ],
      nartis: [
        {
          count: 16670,
        },
      ],
    },
  },
];

export const DATA_DYNAMIC_STATISTIC = [
  {
    title: 'СМР',
    data: [
      {
        date: '2024-10-04',
        count: 2,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-05',
        count: 4,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 2,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 1,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-06',
        count: 5,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 3,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 1,
          },
        ],
      },
      {
        date: '2024-10-07',
        count: 10,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 3,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 3,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
    ],
  },
  {
    title: 'КС СМР',
    data: [
      {
        date: '2024-10-04',
        count: 1,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-05',
        count: 2,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-06',
        count: 6,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-07',
        count: 7,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
    ],
  },
  {
    title: 'КС ПНР',
    data: [
      {
        date: '2024-10-04',
        count: 3,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-05',
        count: 3,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-06',
        count: 4,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-07',
        count: 8,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
    ],
  },
  {
    title: DYNAMIC_SWITCH_LABELS.NARTIS,
    data: [
      {
        date: '2024-10-04',
        count: 13,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-05',
        count: 23,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-06',
        count: 24,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
      {
        date: '2024-10-07',
        count: 28,
        showInfo: [
          {
            title: 'КВАРТИРА 1Ф',
            count: 1,
          },
          {
            title: 'ЛЕСТНИЦА 1Ф',
            count: 2,
          },
          {
            title: 'ХОЛЛ 1Ф',
            count: 3,
          },
        ],
      },
    ],
  },
];

const breakLineIfNumber = (label: string) => {
  const words = label.split(' ');

  const result = [];
  let currentLine = '';

  words.forEach((word) => {
    if (!isNaN(Number(word))) {
      if (currentLine) {
        result.push(currentLine.trim());
      }
      currentLine = word;
    } else {
      currentLine += ` ${word}`;
    }
  });

  if (currentLine) {
    result.push(currentLine.trim());
  }

  return result;
};
