import DateField from '@/components/DateField';
import { useCatalog } from '@/hooks/CatalogHook';
import { color } from '@/styles/mixins';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { KeyString, lookUpExistingEquipment } from './utils';
import { PresentPu } from '@/dto/taskmap/task/TaskResultDto';
import { taskMapToArray } from '../../tasksBrowse/utils';
import { useMemo } from 'react';
import { useExsistingEquipmentValidation } from './forms';

interface FormPresentPuProps {
  keyString: KeyString;
  formData: PresentPu;
}
const FormPresentPu = ({ keyString, formData }: FormPresentPuProps) => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const { getCatalogMapWithErr, referenceMeterType } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    referenceMeterType: state.referenceMeterType,
  }));

  const handleT1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(`${keyString}.measurement.t1`, value);
    if (!value) {
      setValue(`${keyString}.measurement.t2`, '');
      setValue(`${keyString}.measurement.t3`, '');
    }
    updateTariff();
  };

  const handleT2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(`${keyString}.measurement.t2`, value);
    if (!value) {
      setValue(`${keyString}.measurement.t3`, '');
    }
    updateTariff();
  };
  const handleT3Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(`${keyString}.measurement.t3`, value);
    updateTariff();
  };
  const updateTariff = () => {
    const t1 = watch(`${keyString}.measurement.t1`);
    const t2 = watch(`${keyString}.measurement.t2`);
    const t3 = watch(`${keyString}.measurement.t3`);
    const tariff = [t1, t2, t3].filter(Boolean).length;
    setValue(`${keyString}.measurement.tariff`, tariff);
  };

  const optionsReferenceMeterType = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('referenceMeterType')),
    [referenceMeterType]
  );
  const rules = useExsistingEquipmentValidation();
  setValue(
    `${keyString}.type`,
    getCatalogMapWithErr('referenceMeterType').get(formData?.type || '')
  );

  return (
    <>
      <Stack mb={3}>
        <Typography
          variant='h6'
          color={color('trueBlack')}
        >
          {lookUpExistingEquipment[keyString]}
        </Typography>
      </Stack>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.type`}
            control={control}
            rules={rules.puType}
            render={({ field, fieldState }) => (
              <Autocomplete
                sx={{ width: '200px' }}
                id='res'
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={optionsReferenceMeterType}
                getOptionLabel={(option) => option.label}
                value={{
                  value: field?.value ?? '',
                  label:
                    getCatalogMapWithErr('referenceMeterType').get(field?.value) ??
                    getCatalogMapWithErr('referenceMeterType').get(formData?.type || '') ??
                    '',
                }}
                onChange={(event: any, newValue: any) => {
                  field?.onChange(newValue?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    name={`${keyString}.type`}
                    {...params}
                    label={'Тип'}
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.number`}
            control={control}
            defaultValue={formData?.number}
            rules={rules.puNumber}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                id={`${keyString}`}
                sx={{ width: '200px' }}
                label='№'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <TextField
            hidden
            {...register(`${keyString}.balance`, {
              value: formData?.balance,
            })}
          />
          <TextField
            hidden
            {...register(`${keyString}.measurement.tariff`, {
              value: formData?.measurement?.tariff,
            })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.editionYear`}
            control={control}
            defaultValue={formData?.stampNumber}
            rules={rules.puEditionYear}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='Год выпуска'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <DateField
            label={'Дата проверки'}
            defaultValue={formData?.verificationDate}
            hookFormString={`${keyString}.verificationDate`}
            roundTime={false}
          />
        </Grid>
        <Grid item>
          <DateField
            label={'Следующая поверка'}
            defaultValue={formData?.nextVerificationDate}
            hookFormString={`${keyString}.nextVerificationDate`}
            roundTime={false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.stampNumber`}
            control={control}
            defaultValue={formData?.stampNumber}
            rules={rules.puStampNumber}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='Пломба'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.kkiStamp`}
            control={control}
            defaultValue={formData?.kkiStamp}
            rules={rules.puKkiStamp}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='Пломба ККИ'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Антимагнитный регулятор'
            variant='standard'
            sx={{ width: '200px' }}
            {...register(`${keyString}.aiNumber`, {
              value: formData?.aiNumber,
            })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <TextField
            label='Т1'
            variant='standard'
            sx={{ width: '200px' }}
            {...register(`${keyString}.measurement.t1`, {
              value: formData?.measurement?.t1,
              onChange: handleT1Change,
            })}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Т2'
            variant='standard'
            disabled={!watch(`${keyString}.measurement.t1`)}
            sx={{ width: '200px' }}
            {...register(`${keyString}.measurement.t2`, {
              value: formData?.measurement?.t2,
              onChange: handleT2Change,
            })}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Т3'
            variant='standard'
            disabled={
              !watch(`${keyString}.measurement.t1`) || !watch(`${keyString}.measurement.t2`)
            }
            sx={{ width: '200px' }}
            {...register(`${keyString}.measurement.t3`, {
              value: formData?.measurement?.t3,
              onChange: handleT3Change,
            })}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default FormPresentPu;
