import axios, { AxiosResponse } from 'axios';
import { yodaJwt } from '@/app/auth/AuthProvider';

export type ApiResponse<T> = Promise<AxiosResponse<T, any>>;

function getHost() {
  const HOST = import.meta.env.VITE_BUDGET_SERVICE_BASE_URL;

  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к InvestStatisticService API не задан');
  } else return HOST as string;
}
export const getYodaJWTLocal = () => localStorage.getItem(yodaJwt);

const HOST = getHost();

export const InvestStatisticClient = axios.create({
  baseURL: HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const GetInvestStatisticByFilter = ({
  contractIds,
  energyCompanyId,
}: any): ApiResponse<any[]> => {
  const params = new URLSearchParams();

  contractIds.forEach((id: any) => {
    params.append('contractIds', id);
  });

  if (energyCompanyId.length !== 0) {
    params.append('energyCompanyId', energyCompanyId);
  }
  console.log(contractIds);
  const url = HOST + `/statistics/summary?${params.toString()}`;
  return InvestStatisticClient.get(url);
};

export const GetInvestStatisticByEquipmentType = ({
  contractIds,
  equipmentType,
}: any): ApiResponse<any[]> => {
  const params = new URLSearchParams();

  contractIds.forEach((contract: any) => {
    params.append('contractIds', contract.value);
  });
  params.append('equipmentType', equipmentType);
  const url = HOST + `/statistics/chart-data?${params.toString()}`;
  return InvestStatisticClient.get(url);
};

export const GetDynamicStatistic = (equipmentType: string): ApiResponse<any[]> => {
  const url = HOST + `/statistics/dynamic-chart-data?equipmentType=${equipmentType}`;
  return InvestStatisticClient.get(url);
};
