import { styled } from '@mui/material';

export const HighlightText = styled('span')({
  fontWeight: '500',
  marginRight: '5px',
});
export const ExistingEquipmentWrapper = styled('div')({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  position: 'relative',
  paddingRight: 50,
});
export const PresentPuWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 10,
});
