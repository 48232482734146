import { ExistingEquipmentData } from '@/dto/taskmap/task/TaskResultDto';
import { createStore } from '@/utils/createStore';

interface ExistingEquipment {
  setExistingEquipment: (data: any) => void;
  existingEquipment: Record<string, ExistingEquipmentData> | null;
  getExistingEquipmentByKey: (id: string) => ExistingEquipmentData | null;
}

export const useExistingEquipment = createStore<ExistingEquipment>(
  (set, get) => ({
    existingEquipment: null,
    setExistingEquipment: (data: ExistingEquipmentData) => {
      const key = data.taskId || data.vruInputId;
      if (!key) {
        return;
      }
      set((state) => ({
        existingEquipment: {
          ...state.existingEquipment,
          [key]: data,
        },
      }));
    },
    getExistingEquipmentByKey: (key: string) => {
      const equipment = get().existingEquipment;
      if (equipment) {
        return equipment[key];
      }
      return null;
    },
  }),
  'useExistingEquipment'
);
