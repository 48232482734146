import { MeterChangeReworkRow } from '@/components/sections/MeterChangeRework/MeterChangeRework.styled';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import { FormControlLabel, IconButton, styled, Typography } from '@mui/material';

export const ChartWrapper = styled(BoxShadow)({
  padding: '27px',
  marginTop: '20px',
  marginBottom: '40px',
});

export const DoughnutWrapper = styled('div')({
  position: 'relative',
  width: '900px',
  height: '850px',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
});

export const BarWrapper = styled('div')({
  height: '420px',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const ButtonClose = styled(IconButton)({
  marginLeft: 'auto',
});

export const WrapperFilters = styled(MeterChangeReworkRow)({
  position: 'absolute',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingRight: 37,
});

export const BudgetStatisticBarWrapper = styled(BoxShadow)({
  padding: '25px',
  paddingBottom: '90px',
  marginTop: '10px',
  marginBottom: '40px',
});

export const ShowInfoControllLabel = styled(FormControlLabel)({
  marginLeft: '10px',

  '.MuiFormControlLabel-label': {
    userSelect: 'none',
    textTransform: 'uppercase',
    fontSize: 14,
    color: color('grayLabel'),
    fontWeight: 500,
  },
});
interface BarShowInfoProps {
  width: number;
  background: string;
}

interface LabelSwitchProps {
  checked: boolean;
}

export const ShowInfoItem = styled('div')({
  display: 'flex',
  gap: '5px',
  height: '17px',
});

export const TitleShowInfo = styled('div')({
  justifyContent: 'end',
  alignItems: 'center',
  display: 'flex',
});

export const BarShowInfo = styled('div')<BarShowInfoProps>(({ width, background }) => ({
  width: `${width}px`,
  background: background,
}));

export const WrapperSwitch = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const LabelSwitch = styled(Typography)<LabelSwitchProps>(({ checked }) => ({
  textTransform: 'uppercase',
  fontSize: 14,
  color: checked ? '#1976D2' : color('grayLabel'),
  fontWeight: 500,
}));

export const ShowInfoCount = styled('div')({
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
});

export const InvestStatisticTitle = styled('p')({
  fontFamily: 'Roboto',
  fontSize: 20,
  fontWeight: 500,
});
