import { color } from '@/styles/mixins';
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { KeyString, lookUpExistingEquipment } from './utils';
import DateField from '@/components/DateField';
import { DateRange } from '@/components/inputs/DateRange';

import { PresentTransformer } from '@/dto/taskmap/task/TaskResultDto';
import { useExsistingEquipmentValidation } from './forms';
import { useState } from 'react';

interface FromPresentTransformerProps {
  keyString: KeyString;
  formData: PresentTransformer;
}
const FromPresentTransformer = ({ formData, keyString }: FromPresentTransformerProps) => {
  const { register, control } = useFormContext();
  const rules = useExsistingEquipmentValidation();

  return (
    <>
      <Stack
        mb={3}
        mt={3}
      >
        <Typography
          variant='h6'
          color={color('trueBlack')}
        >
          {lookUpExistingEquipment[keyString]}
        </Typography>
      </Stack>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.type`}
            control={control}
            defaultValue={formData?.type}
            rules={rules.ttType}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='Тип'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.ktt`}
            control={control}
            defaultValue={formData?.ktt}
            rules={rules.ktt}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ width: '200px' }}
                id={`${keyString}`}
                label='КТТ'
                variant='standard'
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <TextField
            hidden
            {...register(`${keyString}.accuracy`, {
              value: formData?.accuracy,
            })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        mb={3}
      >
        <Grid item>
          <Controller
            name={`${keyString}.checkDate`}
            control={control}
            defaultValue={formData?.checkDate}
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                label='Дата проверки'
                defaultValue={formData?.checkDate}
                hookFormString={`${keyString}.checkDate`}
                roundTime={false}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name={`${keyString}.nextCheckDate`}
            control={control}
            defaultValue={formData?.nextCheckDate}
            render={({ field, fieldState }) => (
              <DateField
                {...field}
                label={'Следующая поверка'}
                defaultValue={formData?.nextCheckDate}
                hookFormString={`${keyString}.nextCheckDate`}
                roundTime={false}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <Stack>
        {[...formData?.phaseList]
          .sort((a, b) => a.phase.localeCompare(b.phase))
          .map((item, index) => {
            return (
              <Grid
                item
                container
                key={item.phase}
              >
                <Typography
                  variant='h6'
                  color={color('trueBlack')}
                  marginY={2}
                >{`фаза ${item?.phase}`}</Typography>
                <Grid
                  item
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Controller
                      name={`${keyString}.phaseList.${index}.serial`}
                      control={control}
                      defaultValue={formData?.phaseList[index].serial}
                      rules={rules.ttSerial}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          sx={{ width: '200px' }}
                          id={`${keyString}`}
                          label='№ ТН'
                          variant='standard'
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      name={`${keyString}.phaseList.${index}.year`}
                      control={control}
                      defaultValue={formData?.phaseList[index].year}
                      rules={rules.ttYear}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          sx={{ width: '200px' }}
                          id={`${keyString}`}
                          label='Год выпуска'
                          variant='standard'
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      name={`${keyString}.phaseList.${index}.stamp`}
                      control={control}
                      defaultValue={formData?.phaseList[index].stamp}
                      rules={rules.ttStamp}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          sx={{ width: '200px' }}
                          id={`${keyString}`}
                          label='Пломба'
                          variant='standard'
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <TextField
                      hidden
                      {...register(`${keyString}.phaseList.${index}.phase`, {
                        value: formData?.phaseList[index].phase,
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Stack>
    </>
  );
};

export default FromPresentTransformer;
