import React, { FC, useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, TextField } from '@/components/fixUI';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';

interface DateFieldProps {
  label: string;
  disabled?: boolean;
  defaultValue?: Date;
  hookFormString?: string;
  inputFormat?: string;
  required?: boolean;
  roundTime?: boolean;
  value?: Date | null;
  helperText?: string;
  onChange?: (value: Date | null) => void;
  error?: boolean;
}

const roundDate = (val: Date | undefined): Date => {
  return new Date(moment(val).valueOf() - (moment(val).valueOf() % 86400000));
};

const DateField: FC<DateFieldProps> = ({
  disabled = false,
  required = false,
  defaultValue,
  helperText = '',
  label,
  hookFormString,
  inputFormat = 'dd.MM.yyyy',
  roundTime = true,
  value,
  onChange,
  error,
}) => {
  const { setValue } = useFormContext();
  const [dateValue, setDateValue] = useState<Date | null>(value ?? defaultValue ?? null);

  useEffect(() => {
    if (hookFormString && defaultValue !== undefined) {
      const processedValue = roundTime
        ? moment(roundDate(defaultValue)).valueOf()
        : moment(defaultValue).valueOf();

      setValue(hookFormString, processedValue);
    }
  }, [hookFormString, defaultValue, roundTime, setValue]);

  const onHandleChangeDateValue = (newValue: Date | null) => {
    setDateValue(newValue);
    let processedValue: number | null = null;
    if (newValue) {
      if (roundTime) {
        processedValue = moment(roundDate(newValue)).valueOf();
      } else {
        processedValue = moment(newValue).valueOf();
      }
    }
    if (onChange) {
      onChange(newValue); // передаём значение в Controller
    }
    if (hookFormString) {
      setValue(hookFormString, processedValue); // обнавляем значение в useFormContext
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        disabled={disabled}
        label={label}
        inputFormat={inputFormat}
        value={dateValue}
        onChange={onHandleChangeDateValue}
        renderInput={(params) => (
          <TextField
            sx={{ width: '100%' }}
            {...params}
            variant='standard'
            required={required}
            helperText={helperText}
            error={error}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateField;
