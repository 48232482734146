import { DynamicStatisticItem } from '../types';
import { Button, IconButton, Switch, Typography } from '@mui/material';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  DATA_DYNAMIC_STATISTIC,
  generateDataLine,
  getOptionsLineChart,
  timePeriod,
} from '../utils';
import {
  ChartWrapper,
  InvestStatisticTitle,
  ShowInfoControllLabel,
} from '../InvestStatistic.styled';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CloseIcon from '@mui/icons-material/Close';
import { GetDynamicStatistic } from '@/services/InvestStatisticService';
import shallow from 'zustand/shallow';
import { useInvestStatisticStore } from '../store';
import {
  DynamicStatisticSwitchesWrapper,
  DynamicStatisticTimePeriodWrapper,
  HeaderDynamicStatistic,
  LineWrapper,
} from './DynamicStatistic.styled';

export const DynamicStatistic = () => {
  const { equipmentsData, activeIndex, setSelectedColumn, selectedColumn } =
    useInvestStatisticStore(
      (state) => ({
        equipmentsData: state.equipmentsData,
        activeIndex: state.activeIndex,
        setSelectedColumn: state.setSelectedColumn,
        selectedColumn: state.selectedColumn,
      }),
      shallow
    );
  const equipmentType = activeIndex ? equipmentsData[activeIndex].title : '';

  const [dataDynamicStatistic, setDataDynamicStatistic] = useState<DynamicStatisticItem[]>([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState({
    title: 'МЕСЯЦ',
    name: 'month',
  });
  const [withNartis, setWithNartis] = useState(false);
  const refWrapper = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    //запрос на бэк, параметры запроса selectedTimePeriod.name
    refWrapper?.current?.scrollIntoView({ behavior: 'smooth' });
    GetDynamicStatistic(equipmentType).then(({ data }) => setDataDynamicStatistic(data));
  }, [selectedColumn]);

  const closeHandler = () => {
    setSelectedColumn(null);
    setDataDynamicStatistic([]);
  };

  return (
    <div ref={refWrapper}>
      {dataDynamicStatistic && (
        <>
          <InvestStatisticTitle>В ДИНАМИКЕ</InvestStatisticTitle>
          <ChartWrapper>
            <HeaderDynamicStatistic>
              <DynamicStatisticTimePeriodWrapper>
                {timePeriod.map((item, idx) => (
                  <Button
                    key={idx}
                    disabled={item.name !== 'month'}
                    variant={selectedTimePeriod.name === item.name ? 'contained' : 'outlined'}
                    onClick={() => setSelectedTimePeriod(item)}
                  >
                    {item.title}
                  </Button>
                ))}
              </DynamicStatisticTimePeriodWrapper>
              <DynamicStatisticSwitchesWrapper>
                <ShowInfoControllLabel
                  control={
                    <Switch
                      checked={withNartis}
                      onChange={(e) => setWithNartis(e.target.checked)}
                    />
                  }
                  label={'установлено пу нартис'}
                />
                <IconButton onClick={closeHandler}>
                  <CloseIcon />
                </IconButton>
              </DynamicStatisticSwitchesWrapper>
            </HeaderDynamicStatistic>

            <LineWrapper>
              <Line
                data={generateDataLine(dataDynamicStatistic, withNartis)}
                options={getOptionsLineChart(dataDynamicStatistic)}
                plugins={[ChartDataLabels]}
              />
            </LineWrapper>
          </ChartWrapper>
        </>
      )}
    </div>
  );
};
