import { getTKOStausByIdTask } from '@/services/TaskService';
import { GetCurrentUser } from '@/services/YodaRestService';
import { Button, Stack, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCallingMapState } from '../store';
import { useActionLog } from '@/hooks/ActionLogHook';
import { responseToMap } from '@/utils/heplers';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import shallow from 'zustand/shallow';

interface TkoFormData {
  tkoEnumId: string;
  commentTKO: string;
  executor: string | null;
  tkoDate: string;
}
interface CreatTkoProps {
  setOpenTkoForm: React.Dispatch<React.SetStateAction<boolean>>;
  openTkoForm: boolean;
}

export default function CreatTko({ setOpenTkoForm, openTkoForm }: CreatTkoProps) {
  const [statusCatalog, setStatusCatalog] = useState<Map<string, string>>(new Map());
  const { register, setValue } = useFormContext<TkoFormData>();
  const { singleGeoPoint } = useCallingMapState(
    (state) => ({
      singleGeoPoint: state.singleGeoPoint,
    }),
    shallow
  );

  const { fetchCatch } = useActionLog();
  useEffect(() => {
    if (singleGeoPoint?.contractId) {
      getTKOStausByIdTask(singleGeoPoint.contractId).then(({ data }) =>
        setStatusCatalog(responseToMap(data))
      );
    }
    GetCurrentUser()
      .then(({ data }) => {
        setValue('executor', data?.fullName ?? null);
      })
      .catch((err) => fetchCatch(err, 'Ошибка получения данных о пользователе'));
  }, []);

  const disableCreateTkoBtn = statusCatalog.size === 0;

  return (
    <Stack>
      <Grid
        container
        spacing={2}
      >
        <Grid
          xs={12}
          item
        >
          <Button
            variant='outlined'
            fullWidth
            type='submit'
            color='error'
            disabled={disableCreateTkoBtn}
            onClick={() => setOpenTkoForm((prev) => !prev)}
          >
            {statusCatalog.size ? `создать тко` : `нет статусов для создания тко`}
          </Button>
        </Grid>
        {openTkoForm && (
          <>
            <Grid
              item
              xs={6}
            >
              <AutocompleteValue
                title='Статус ТКО'
                fieldName={`tkoEnumId`}
                values={statusCatalog}
                required={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='outlined-basic'
                label='Коментарий ТКО'
                variant='outlined'
                multiline
                fullWidth
                rows={2}
                {...register(`commentTKO`)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}
