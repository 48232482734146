import { createStore } from '@/utils/createStore';
import { StatisticInvestProgramItem } from './types';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';

interface InvestStatisticData {
  equipmentsData: StatisticInvestProgramItem[];
  showInfo: boolean;
  selectedContract: SelectFilterOptType[];
  activeIndex: number | null;
  selectedColumn: number | null;
}

interface InvestStatisticFunctions {
  setEquipmentsData: (equipmentsData: StatisticInvestProgramItem[]) => void;
  setShowInfo: (showInfo: boolean) => void;
  setSelectedContract: (selectedContract: SelectFilterOptType[]) => void;
  setActiveIndex: (activeIndex: number | null) => void;
  setSelectedColumn: (selectedColumn: number | null) => void;
}
export interface InvestStatisticStore extends InvestStatisticData, InvestStatisticFunctions {}

export const useInvestStatisticStore = createStore<InvestStatisticStore>(
  (set, get) => ({
    equipmentsData: [],
    showInfo: false,
    selectedContract: [],
    activeIndex: null,
    selectedColumn: null,

    setEquipmentsData: (equipmentsData: StatisticInvestProgramItem[]) => set({ equipmentsData }),
    setShowInfo: (showInfo: boolean) => set({ showInfo }),
    setSelectedContract: (selectedContract: SelectFilterOptType[]) => set({ selectedContract }),
    setActiveIndex: (activeIndex: number | null) => set({ activeIndex }),
    setSelectedColumn: (selectedColumn: number | null) => set({ selectedColumn }),
  }),
  'InvestStatistic'
);
