import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import DraggablePopup from '@/components/popups/DraggablePopup';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { filterObjectsWithValues } from './utils';
import MainWrapperExistingEquipment from './MainWrapperExistingEquipment';
import FormForExistingEquipment from './FormForExistingEquipment';

import { useGalleryData } from '@/components/Gallery/store';
import { getExistingEquipmentImageGroup } from '../panel/utils';
import { useExistingEquipment } from './store';
import shallow from 'zustand/shallow';
import { ExistingEquipmentWrapper } from './ExistingEquipment.styled';
import { useStatus } from '../panel/store';

interface ExistingEquipmentnProps {
  id: string;
  existingEquipmentUrl: string;
}
const ExistingEquipment = ({ id, existingEquipmentUrl }: ExistingEquipmentnProps) => {
  const { getExistingEquipmentByKey } = useExistingEquipment(
    (store) => ({
      getExistingEquipmentByKey: store.getExistingEquipmentByKey,
    }),
    shallow
  );
  const existingEquipment = getExistingEquipmentByKey(id);

  const existingEquipmentArr =
    (existingEquipment?.presentEquipments &&
      filterObjectsWithValues(existingEquipment?.presentEquipments)) ||
    [];

  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups(getExistingEquipmentImageGroup(existingEquipment));
  };

  const methods = useForm<any>();
  const [open, setOpen] = useState<boolean>(false);
  const { isDisabledAll } = useStatus(
    (store) => ({
      isDisabledAll: store.isDisabledAll,
    }),
    shallow
  );

  return (
    <FormProvider {...methods}>
      <Grid item>
        <BoxShadow onDoubleClick={handleClick}>
          <ExistingEquipmentWrapper>
            <div>
              {existingEquipmentArr.map((equipment) => (
                <MainWrapperExistingEquipment
                  key={equipment?.key}
                  existingEquipment={equipment}
                />
              ))}
            </div>
            <IconButton
              style={{ position: 'absolute', right: -13, top: 0 }}
              onClick={() => setOpen(true)}
              disabled={isDisabledAll()}
            >
              <BorderColorIcon htmlColor={color('muiBlue')} />
            </IconButton>
          </ExistingEquipmentWrapper>
        </BoxShadow>
      </Grid>
      <DraggablePopup
        onClose={() => setOpen(false)}
        isOpen={open}
      >
        <div>
          <FormForExistingEquipment
            id={id}
            closeModal={() => setOpen(false)}
            existingEquipmentUrl={existingEquipmentUrl}
          />
        </div>
      </DraggablePopup>
    </FormProvider>
  );
};

export default ExistingEquipment;
