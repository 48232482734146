import React from 'react';
import { BudgetStatisticBar } from './BudgetStatisticBar';
import { DynamicStatistic } from './DynamicStatistic/DynamicStatistic';
import './InvestStatistic.css';
import { ChartWrapper } from './InvestStatistic.styled';
import BudgetStatisticDoughnut from './BudgetStatisticDoughnut';
import { useInvestStatisticStore } from './store';
import shallow from 'zustand/shallow';

export const InvestStatistic = () => {
  const { equipmentsData, activeIndex, selectedColumn } = useInvestStatisticStore(
    (state) => ({
      equipmentsData: state.equipmentsData,
      activeIndex: state.activeIndex,
      selectedColumn: state.selectedColumn,
    }),
    shallow
  );

  return (
    <>
      {equipmentsData && <BudgetStatisticDoughnut />}

      {activeIndex !== null && <BudgetStatisticBar />}

      {selectedColumn !== null && activeIndex !== null && <DynamicStatistic />}
    </>
  );
};
