/* eslint-disable */
//@ts-nocheck
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { contractListResponse, DATA_COLUMNS, filterShowInfo, getBarOptions } from './utils';
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { StatisticBudgetColumnItem, TooltipBarChart } from './types';
import {
  BarShowInfo,
  BarWrapper,
  BudgetStatisticBarWrapper,
  ButtonClose,
  LabelSwitch,
  ShowInfoControllLabel,
  ShowInfoCount,
  ShowInfoItem,
  TitleShowInfo,
  WrapperSwitch,
  InvestStatisticTitle,
} from './InvestStatistic.styled';
import CloseIcon from '@mui/icons-material/Close';
import { GetInvestStatisticByEquipmentType } from '@/services/InvestStatisticService';
import shallow from 'zustand/shallow';
import { useInvestStatisticStore } from './store';
import * as d3 from 'd3';

export const BudgetStatisticBar = () => {
  const { equipmentsData, selectedContract, activeIndex, setActiveIndex, setSelectedColumn } =
    useInvestStatisticStore(
      (state) => ({
        equipmentsData: state.equipmentsData,
        selectedContract: state.selectedContract,
        activeIndex: state.activeIndex,
        setActiveIndex: state.setActiveIndex,
        setSelectedColumn: state.setSelectedColumn,
      }),
      shallow
    );
  const title = activeIndex !== null ? equipmentsData[activeIndex].title : '';

  const [checkedEnergyCompany, setCheckedEnergyCompany] = useState(false);
  const [dataColumns, setDataColumns] = useState<StatisticBudgetColumnItem[]>(DATA_COLUMNS);
  const refWrapper = useRef() as MutableRefObject<HTMLDivElement>;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [displayNartis, setDisplayNartis] = useState(false);
  const [tooltip, setTooltip] = useState<TooltipBarChart>({
    display: 'none',
    tooltipData: null,
    top: 0,
    left: 0,
  });

  useEffect(() => {
    //запрос на бэк
    // параметры запроса: checkedEnergyCompany ? energyCompany : contract
    refWrapper?.current?.scrollIntoView({ behavior: 'smooth' });
    // setDataColumns(DATA_COLUMNS);
    GetInvestStatisticByEquipmentType({
      contractIds: selectedContract,
      equipmentType: title,
    }).then(({ data }) => {
      setDataColumns(data);
      setSelectedColumn(null);
    });
  }, [title]);

  const tooltipRef = useRef(null);

  //   FIXME: не оптимально, постоянно вычисляем размеры
  const handleMouseOver = (event, d) => {
    const { width, height } = tooltipRef.current.getBoundingClientRect();

    const { clientX, clientY } = event;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const tooltipWidth = width || 150; // Фолбэк на случай отсутствия данных
    const tooltipHeight = height || 50;

    //   FIXME: хардкод магич. числа 15, сделать по аналогии с бубликом
    let top = clientY + 15;
    let left = clientX + 15;

    // Корректировка координат для границ
    if (left + tooltipWidth > viewportWidth) {
      left = viewportWidth - tooltipWidth;
    }
    if (top + tooltipHeight > viewportHeight) {
      top = viewportHeight - tooltipHeight;
    }

    setTooltip({
      display: 'block',
      tooltipData: filterShowInfo(d?.showInfo?.showInfo, displayNartis),
      top,
      left,
    });
  };

  useEffect(() => {
    d3.select(svgRef.current).selectAll('*').remove();

    const width = 1700;
    const height = 500;
    const margin = { top: 20, right: 30, bottom: 40, left: 60 };

    d3.select(svgRef.current).attr('width', width).attr('height', height);

    const x0 = d3
      .scaleBand()
      .domain(dataColumns.map((d) => d.catalogId))
      .range([margin.left, width - margin.right])
      .padding(0.2);

    const segmentKeys = d3.range(dataColumns[0]?.segmentParts.default.length).map(String);
    const x1 = d3.scaleBand().domain(segmentKeys).range([0, x0.bandwidth()]);

    const barWidth = x1.bandwidth() < 90 ? x1.bandwidth() : 90;

    const maxCount =
      d3.max(dataColumns, (d) => d3.max(d.segmentParts.default.map((item) => item.count))) || 0;
    const padding = maxCount * 0.05;
    const paddedMaxValue = maxCount + padding;

    const y = d3
      .scaleLinear()
      .domain([0, paddedMaxValue])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const svg = d3.select(svgRef.current);
    svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(
        d3
          .axisBottom(x0)
          .tickFormat(
            (catalogId) => contractListResponse.find((it) => it.value === catalogId)?.label
          )
      )
      .selectAll('text')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold');

    svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).ticks(5))
      .attr('font-size', '12px')
      .attr('font-weight', 'bold');

    function addHorizontalGridlines() {
      svg
        .append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).ticks(5).tickSize(-width).tickFormat(''))
        .selectAll('.tick line')
        .attr('class', 'grid-line');
    }

    addHorizontalGridlines();

    const stack = d3.stack().keys(segmentKeys);

    const originalCounts = dataColumns.map((item) =>
      item.segmentParts.default.map((it) => it.count)
    );

    const stackedData = stack(
      dataColumns?.map((item, i) =>
        item.segmentParts.default.map((it, idx) => {
          const segments = [...item.segmentParts.default].sort((a, b) => a.count - b.count);

          const transformValue = (value: number) => {
            if ((value * 100) / paddedMaxValue < 5) {
              value = paddedMaxValue * 0.05;
            }
            return value;
          };

          return segments[idx - 1]
            ? transformValue(segments[idx]?.count - segments[idx - 1]?.count)
            : transformValue(segments[idx]?.count);
        })
      )
    );
    const detailedStackedData = stackedData.map((d, index) =>
      d.map((segment, i) => {
        return {
          ...segment,
          showInfo: dataColumns[i].segmentParts.default.sort((a, b) => a.count - b.count)[
            stackedData[index].index
          ],
          index: i,
        };
      })
    );

    const backgroundColorGradient = [
      ['#588ABE', '#294058'],
      ['#A3CCE9', '#5C7383'],
      ['transparent', 'transparent'],
    ];

    detailedStackedData.forEach((d, i) => {
      const gradient = svg
        .append('defs')
        .append('linearGradient')
        .attr('id', `gradient-${i}`)
        .attr('x1', '0%')
        .attr('x2', '100%')
        .attr('y1', '0%')
        .attr('y2', '100%');

      gradient
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', backgroundColorGradient[i][0])
        .attr('stop-opacity', 1);

      gradient
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', backgroundColorGradient[i][1])
        .attr('stop-opacity', 1);
    });

    svg
      .append('g')
      .selectAll('g')
      .data(detailedStackedData)
      .join('g')
      .attr('fill', (d, i) => `url(#gradient-${i})`)
      .on('mouseout', () => {
        setTooltip({ display: 'none', tooltipData: null, top: 0, left: 0 });
      })
      .each(function (d, i) {
        d.forEach((segment, j) => {
          const x: number | undefined =
            x0(dataColumns[segment.index].catalogId) + x0.bandwidth() / 2 - 0.5 * barWidth;
          const width = barWidth;

          const y0 = y(segment[0]);
          const y1 = y(segment[1]);

          const valueText = dataColumns[j].segmentParts.default.sort((a, b) => a.count - b.count)[i]
            .count;

          svg
            .append('text')
            .attr('x', x + width / 2)
            .attr('y', i === 2 ? y1 - 10 : (y0 + y1) / 2)
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .style('pointer-events', 'none')
            .text(valueText)
            .attr('fill', i === 2 ? 'black' : 'white')
            .attr('font-size', '14px')
            .attr('font-weight', 'bold');
        });
      })
      .selectAll('rect')
      .data((d) => d)
      .join('rect')
      .attr('x', (d, i) => x0(dataColumns[i].catalogId) + x0.bandwidth() / 2 - 0.5 * barWidth)
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => y(d[0]) - y(d[1]))
      .attr('width', barWidth)
      .each(function (d, i) {
        const upperSegment = detailedStackedData[2];
        const x: number | undefined =
          x0(dataColumns[i].catalogId) + x0.bandwidth() / 2 - 0.5 * barWidth;
        if (!x) {
          return;
        }
        const yTop = y(upperSegment[i][1]);

        const width = barWidth;
        const height = y(upperSegment[i][1]) - y(upperSegment[i][0]);

        svg
          .append('line')
          .attr('x1', x)
          .attr('y1', yTop)
          .attr('x2', x + width)
          .attr('y2', yTop)
          .attr('stroke', '#A3CCE9')
          .attr('stroke-dasharray', '3,3')
          .attr('stroke-width', 1);

        svg
          .append('line')
          .attr('x1', x)
          .attr('y1', yTop)
          .attr('x2', x)
          .attr('y2', yTop - height)
          .attr('stroke', '#A3CCE9')
          .attr('stroke-dasharray', '3,3')
          .attr('stroke-width', 1);

        svg
          .append('line')
          .attr('x1', x + width)
          .attr('y1', yTop)
          .attr('x2', x + width)
          .attr('y2', yTop - height)
          .attr('stroke', '#A3CCE9')
          .attr('stroke-dasharray', '3,3')
          .attr('stroke-width', 1);
      })
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseOver)
      .on('click', (event, d) => {
        setSelectedColumn(d.index);
      });

    //если есть нартисы
    if (displayNartis) {
      svg
        .append('g')
        .selectAll('rect')
        .data(dataColumns)
        .join('path')
        .attr('d', (d) => {
          const x = x0(d.catalogId) + x0.bandwidth() / 2 + 0.5 * barWidth;
          const width = x1.bandwidth() < 14 ? x1.bandwidth() : 14;
          const yValue = y(d.segmentParts.nartis[0]?.count);

          return `M${x},${yValue} 
                    L${x + width - 10},${yValue} 
                    Q${x + width},${yValue} ${x + width},${yValue + 10} 
                    L${x + width},${y(0)} 
                    L${x},${y(0)} 
                    Z`;
        })
        .attr('fill', '#56CCF2');
    }
  }, [dataColumns, displayNartis]);

  // const dataBar = useMemo(() => generateDataBar(dataColumns), [dataColumns]);

  // const optionsBar = useMemo(() => getBarOptions(setSelectedColumn, dataColumns), [dataColumns]);

  const closeHandler = () => {
    setActiveIndex(null);
    setDataColumns([]);
  };

  const backgrounds = ['#E1265B', '#EB7B9B', '#E1265B40', '#56CCF2'];
  let lengthTitle = 0;
  return (
    <div ref={refWrapper}>
      <InvestStatisticTitle>{title}</InvestStatisticTitle>
      <BudgetStatisticBarWrapper>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '11px',
          }}
        >
          <WrapperSwitch>
            <LabelSwitch
              checked={!checkedEnergyCompany}
              sx={{ cursor: 'pointer' }}
            >
              КОНТРАКТ
            </LabelSwitch>
            <Switch
              disabled
              checked={checkedEnergyCompany}
              onChange={(e) => setCheckedEnergyCompany(e.target.checked)}
            />
            <LabelSwitch
              checked={checkedEnergyCompany}
              sx={{ cursor: 'pointer' }}
            >
              СБЫТ
            </LabelSwitch>
          </WrapperSwitch>
          <div>
            <ShowInfoControllLabel
              style={{ marginLeft: 'auto' }}
              control={
                <Switch
                  checked={displayNartis}
                  onChange={(e) => setDisplayNartis(e.target.checked)}
                />
              }
              label={'установлено пу нартис'}
            />
            <ButtonClose onClick={closeHandler}>
              <CloseIcon />
            </ButtonClose>
          </div>
        </Grid>
        <BarWrapper>
          <svg
            ref={svgRef}
            width={500}
            height={200}
          />
          <div
            style={{
              visibility: tooltip.display === 'block' ? 'visible' : 'hidden',
              opacity: tooltip.display === 'block' ? 1 : 0,
              position: 'fixed',
              top: tooltip.top,
              left: tooltip.left,
              transition: 'opacity 0.3s ease',
              pointerEvents: 'none',
            }}
            ref={tooltipRef}
          >
            {tooltip?.tooltipData && !!Object.values(tooltip?.tooltipData)?.length && (
              <>
                <div className='show-info-bar'>
                  {Object.values(tooltip?.tooltipData).map((item, index) => {
                    const max = Math.max(...item.map((it) => it.count));

                    return (
                      <>
                        {item.map((itemValue, idx) => {
                          if (itemValue.title.length > lengthTitle) {
                            lengthTitle = itemValue.title.length;
                          }
                          return (
                            <ShowInfoItem
                              style={{ marginTop: index > 0 && idx === 0 ? '15px' : 0 }}
                              key={idx}
                            >
                              <TitleShowInfo style={{ width: `${lengthTitle * 10}px` }}>
                                {itemValue.title}
                              </TitleShowInfo>
                              <BarShowInfo
                                width={(itemValue.count * 170) / max}
                                background={backgrounds[index > 0 ? 3 : idx]}
                              />
                              <ShowInfoCount>
                                <div>{itemValue.count}</div>
                                {itemValue.percentage && <div>{itemValue.percentage}%</div>}
                              </ShowInfoCount>
                            </ShowInfoItem>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </BarWrapper>
      </BudgetStatisticBarWrapper>
    </div>
  );
};
