import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';

export interface SupplyStampRequest {
  // stamp number
  number: string;
  // supply bill id
  billId: string;
}

export interface SupplyPuRequest {
  billId: string;
  mac: string;
  number: string;
  supplyPuTypeId: number;
}

export interface SupplyPuItemDto {
  id: string;
  number: string;
  mac: string;
}

export interface SupplyNetworkElementDto {
  // supply network element type id
  typeId: number;
  typeName: string;
  // field validator
  typeRegex: string;
  networkElementItems: SupplyNetworkElementItemDto[];
}

export interface SupplyNetworkElementItemDto {
  // supplyNetworkElement id
  id: string;
  // serial number
  imei: string;
  // mac number
  mac: string;
}

export interface SupplyPuFilterParam {
  puNumber: string | null;
  mac: string | null;
  typeId: number;
  billTypeId: number;
}

export interface SupplyOsFilterParam {
  imei: string | null;
  mac: string | null;
  typeId: number;
  billTypeId: number;
}

export interface SupplyNetworkElementFilterParam {
  imei: string;
  mac: string;
  typeId: number;
}

export interface SupplyBillFilterParam {
  contractorIdList?: string[];
  billTypeArg?: FilterArgEnum;
  billType?: string[];
  billNumberArg?: FilterArgEnum;
  billNumber?: string;
  puNumberArg?: FilterArgEnum;
  puNumber?: string;
  contractorIdArg?: FilterArgEnum;
  contractorId?: string[];
  billDateArg?: FilterArgEnum;
  billDate?: string[] | string;
  puTypeIdArg?: FilterArgEnum;
  puTypeId?: string[];
  releasedWithoutProjectId?: boolean;
}

export interface SupplyBillTypeResponse {
  id: number;
  name: string;
}

export interface SupplyBillSearchFilter {
  filter: SupplyBillFilterParam;
  page: number;
  size: number;
}

export interface SupplyPuTypeResponse {
  id: number;
  name: string;
  fullName: string;
  comment: string;
  manufactureYear: string | null;
  puNumRegexExpr?: string | null;
  meterTypeScanField: string;
  parentId: number | null;
  isRim: boolean;
  yodaManufactureId: string | null;
}

export interface SupplyPuTypeCreateDto
  extends Omit<SupplyPuTypeResponse, 'id' | 'meterTypeScanField'> {
  id: number | null;
  meterTypeScanFieldValue: string;
}

export enum SupplyNetworkElementScanField {
  MAC = 'MAC',
  IMEI = 'IMEI',
  MAC_AND_IMEI = 'MAC_AND_IMEI',
}

export interface SupplyNetworkElementTypeResponse {
  id: number;
  name: string;
  fullName: string;
  comment: string;
  manufactureYear: string | null;
  numRegexExpr: string | null;
  typeScanField: SupplyNetworkElementScanField;
  parentId: number | null;
  sortBy: number;
}

export interface SupplyNetworkElementTypeCreateDto
  extends Omit<SupplyNetworkElementTypeResponse, 'id' | 'meterTypeScanField' | 'numRegexExpr'> {
  id: number | null;
  regexExpr: string | null;
}
