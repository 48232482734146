import {
  ActDateHistoryResponse,
  CallCenterFilterParam,
  CallCenterRequestTableItem,
  Siblings,
  TaskFilterParam,
  WarrantyWorkType,
} from '@/dto/taskmap/Dto';
import { AxiosResponse } from 'axios';
import {
  IsuTaskInCheckDto,
  PillarRequest,
  ReturnTaskBackDto,
  SendAllCipher,
  TaskRequest,
  TaskResponse,
} from '@/dto/taskmap/task/TasksDto';
import { Task } from '@/services/Yoda/TaskService/TaskServiceResponseDto';
import { HOST, yodaGetEntities, yodaPostEntities } from './YodaRestService';
import {
  DeliveryPointResponse,
  ExistingEquipmentData,
  ExtraConnectionNewRequest,
  ExtraConnectionRequest,
  ExtraConnectionResponse,
  PillarResponse,
  PpoCableResponse,
  PpoInputExpendabResponse,
  ReplacementResponseList,
  SubscriberResponse,
  TaskComment,
  TaskResultType,
  TechInspectionResponse,
} from '@/dto/taskmap/task/TaskResultDto';
import { axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPut } from './helpers';
import { ApiResponse } from '@/types/api';

export function AllTasks(): Promise<AxiosResponse<Task[], any>> {
  return yodaGetEntities('enerstroymain_Task');
}

export function CreateTask(task: Task): Promise<AxiosResponse<Task[], any>> {
  return yodaPostEntities('enerstroymain_Task', task);
}

export async function getisuTaskInCheck(
  filter: TaskFilterParam
): Promise<AxiosResponse<IsuTaskInCheckDto[], any>> {
  const url = HOST + `/rest/task/status/isu/all-in-check`;
  return axiosPost(url, filter);
}
export async function getisuTasksById(id: string) {
  const url = HOST + '/rest/v2/entities/enerstroymain_Task/' + id + '?view=mobile-view';
  return axiosGet(url);
}

export async function putisuTasksById(id: string, data: any) {
  const url = HOST + '/rest/v2/entities/enerstroymain_Measurement/' + id;
  return axiosPut(url, data);
}

export async function getCallCenterRequests(
  filter: CallCenterFilterParam,
  offset: number,
  limit: number
): Promise<AxiosResponse<CallCenterRequestTableItem[], any>> {
  const url =
    HOST +
    `/rest/callcentre/find-callcenter-requests-byparam` +
    '?offset=' +
    offset +
    '&limit=' +
    limit;
  return axiosPost(url, filter);
}

export async function getCountOfCallCenterRequests(
  filter: CallCenterFilterParam
): Promise<AxiosResponse<number, any>> {
  const url = HOST + `/rest/callcentre/get-count-callcenter-requests-byparam`;
  return axiosPost(url, filter);
}

export async function completeIsuTaskInCheck(
  isuTaskDto: IsuTaskInCheckDto
): Promise<AxiosResponse<IsuTaskInCheckDto, any>> {
  const url = HOST + `/rest/task/status/isu/complete-in-check`;
  return axiosPost(url, isuTaskDto);
}

export async function findTaskById(id: string): Promise<AxiosResponse<TaskResponse, any>> {
  const url = HOST + `/rest/task/${id}`;
  return axiosGet(url);
}

export async function findAllCatalogByType(
  type: string
): Promise<AxiosResponse<Map<string, string>, any>> {
  const url = HOST + `/rest/catalog/${type}`;
  return axiosGet(url);
}

export async function energyCompanyDistinctName(): Promise<AxiosResponse<string[], any>> {
  const url = HOST + `/rest/energy-company/distinct-name`;
  return axiosGet(url);
}

export async function energyCompanyDistinctResName(): Promise<AxiosResponse<string[], any>> {
  const url = HOST + '/rest/energy-company/distinct-res-name';
  return axiosGet(url);
}
export async function energyCompanyDistinctResNameById(
  contractId: string
): Promise<AxiosResponse<{ [x: string]: string }, any>> {
  const url = HOST + `/rest/res/contract/${contractId}`;
  return axiosGet(url);
}

export async function updateTask(taskId: string, taskRequest: TaskRequest) {
  const url = HOST + `/rest/task/${taskId}`;
  return axiosPut(url, taskRequest);
}

export async function checkTask(taskId: string) {
  const url = HOST + `/rest/task/${taskId}/reopen`;
  return axiosPut(url, {});
}

export async function toCloseTask(taskId: string) {
  const url = HOST + `/rest/task/${taskId}/close`;
  return axiosPost(url, null);
}

export async function toChangeTaskType(taskId: string, taskType: string, message: string) {
  const url = HOST + `/rest/task/${taskId}/${taskType}`;
  return axiosPost(url, { value: message });
}

export async function toCloseTaskIgnoreYunga(taskId: string) {
  const url = HOST + `/rest/task/${taskId}/close-ignore-yunga`;
  return axiosPost(url, null);
}

export async function getLastValidYungaAnalyzeLog(taskId: string) {
  const url = HOST + `/rest/task/${taskId}/last-valid-yunga-analyze-log`;
  return axiosGet(url);
}

export async function toReturnTask(taskId: string, taskRequest: TaskRequest) {
  const url = HOST + `/rest/task/${taskId}/return`;
  return axiosPut(url, taskRequest);
}

export async function toReturnNotRelevantTask(taskId: string, taskRequest: TaskRequest) {
  const url = HOST + `/rest/task/${taskId}/return-not-relevant`;
  return axiosPut(url, taskRequest);
}

export async function findTransStationByTaskId(taskId: string) {
  const url = HOST + `/rest/transstation/${taskId}`;
  return axiosGet(url);
}
export async function saveTransStationByTaskId(taskId: string, transData: any) {
  const url = HOST + `/rest/transstation/${taskId}`;
  return axiosPut(url, transData);
}

export async function createTaskByDeliveryPointId(
  deliveryPointId: string
): Promise<AxiosResponse<DeliveryPointResponse, any>> {
  const url = HOST + `/rest/task/delivery-point/${deliveryPointId}`;
  return axiosPost(url, {});
}

export async function findDeliveryPointById(
  deliveryPointId: string
): Promise<AxiosResponse<DeliveryPointResponse, any>> {
  const url = HOST + `/rest/delivery-point/${deliveryPointId}`;
  return axiosGet(url);
}

export async function saveDeliveryPointById(
  deliveryPoint: DeliveryPointResponse,
  deliveryPointId: string
): Promise<AxiosResponse<DeliveryPointResponse, any>> {
  const url = HOST + `/rest/delivery-point/${deliveryPointId}`;
  return axiosPut(url, deliveryPoint);
}

export async function deleteDeliveryPointById(
  deliveryPointId: string
): Promise<AxiosResponse<DeliveryPointResponse, any>> {
  const url = HOST + `/rest/delivery-point/${deliveryPointId}`;
  return axiosDelete(url);
}
export async function searchSubscriberByTerm(
  serchTerm: string,
  offset = 0,
  limit = 100
): Promise<AxiosResponse<SubscriberResponse[], any>> {
  const url =
    HOST +
    `/rest/subscriber/find-all-by-param?lookupRow=${serchTerm}&offset=${offset}&limit=${limit}`;
  return axiosGet(url);
}

export async function replaceSubscriber(
  subscriber: any,
  deliveryPointId: string
): Promise<AxiosResponse<DeliveryPointResponse, any>> {
  const url = HOST + `/rest/delivery-point/${deliveryPointId}/${subscriber.id}`;
  return axiosPatch(url, subscriber);
}

export async function findAllResultByTaskIdAndType(
  taskId: string,
  type: TaskResultType
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/task/${taskId}/${type}`;
  return axiosGet(url);
}
export async function findODPUHouseInfo(taskId: string) {
  const url =
    HOST +
    `/rest/odpu-house-info/${taskId}
`;
  return axiosGet(url);
}
export async function createODPUHouseInfoApi(taskId: string, body: any) {
  const url = HOST + `/rest/odpu-house-info`;
  return axiosPost(url, { taskId, ...body });
}
export async function updateODPUHouseInfoApi(taskId: string, body: any) {
  const url = HOST + `/rest/odpu-house-info`;
  return axiosPut(url, { taskId, ...body });
}

export async function findAllTaskCommentById(
  taskId: string
): Promise<AxiosResponse<TaskComment[], any>> {
  const url = HOST + `/rest/task/${taskId}/comment`;
  return axiosGet(url);
}

export async function saveTaskComment(taskId: string, message: string) {
  const url = HOST + `/rest/task/${taskId}/comment`;
  return axiosPost(url, message);
}

export async function savePpoOdpu(taskId: string, data: any) {
  const url = HOST + `/rest/task/ppo/${taskId}`;
  return axiosPut(url, data);
}

export async function editPpoVruCableList(data: PpoCableResponse[]) {
  const url = HOST + `/rest/cable`;
  return axiosPut(url, data);
}

export async function deletePpoVruCable(cableId: string) {
  const url = HOST + `/rest/cable/${cableId}`;
  return axiosDelete(url);
}

export async function addPpoVruCable(
  vruInputId: string
): Promise<AxiosResponse<PpoCableResponse, any>> {
  const url = HOST + `/rest/cable/ppo-odpu-input/${vruInputId}`;
  return axiosPost(url, {});
}

export async function getWarranty(
  taskId: string,
  vruInputId: string
): Promise<AxiosResponse<WarrantyWorkType[], any>> {
  const url = HOST + `/rest/warranty-work/task/${taskId}/vru-input/${vruInputId}`;
  return axiosGet(url);
}

export async function saveLockerPpoOdpu(taskId: string, lockerId: string, data: any) {
  const url = HOST + `/rest/task/ppo/${taskId}/ppo-locker/${lockerId}`;
  return axiosPut(url, data);
}
export async function createLockerPpoOdpu(data: any) {
  const url = HOST + `/rest/ppo-odpu-locker`;
  return axiosPost(url, data);
}
export async function deleteLockerPpoOdpu(lockerId: string) {
  const url = HOST + `/rest/task/ppo/ppo-locker/${lockerId}`;
  return axiosDelete(url);
}

export async function deleteODPUVru(vruId: string) {
  const url = HOST + `/rest/odpu/vru/${vruId}`;
  return axiosDelete(url);
}
export async function deleteODPUInput(inputId: string) {
  const url = HOST + `/rest/odpu/vru-input/${inputId}`;
  return axiosDelete(url);
}

export async function deletePPOODPUVru(vruId: string) {
  const url = HOST + `/rest/task/ppo/ppo-odpu-vru/${vruId}`;
  return axiosDelete(url);
}
export async function deletePPOODPUInput(inputId: string) {
  const url = HOST + `/rest/task/ppo/ppo-odpu-input/${inputId}`;
  return axiosDelete(url);
}

export async function updateODPUInput(inputId: string, data: any) {
  const url = HOST + `/rest/odpu/vru-input/${inputId}`;
  return axiosPost(url, data);
}

export async function addTKO(data: any, taskId: string | undefined) {
  const url = HOST + `/rest/task/${taskId}/tech-inspection`;
  return axiosPost(url, data);
}
export async function updateTkoCuba(id: string, data: any) {
  const url = HOST + `/rest/v2/entities/enerstroymain_TKO/${id}`;
  return axiosPut(url, data);
}

export async function updateTko(id: string, data: any) {
  const url = HOST + `/rest/tech-inspection/${id}`;
  return axiosPut(url, data);
}

export async function getCatalogTKOStatusByContractId(id: string) {
  const url = HOST + `/rest/catalog/tko-status/${id}`;
  return axiosGet(url);
}
export async function deleteTKOById(id: string) {
  const url = HOST + `/rest/tech-inspection/${id}`;
  return axiosDelete(url);
}

export async function createPillar(
  data: PillarRequest
): Promise<AxiosResponse<PillarRequest, any>> {
  const url = HOST + `/rest/task/fz522-or-tech-connection`;
  return axiosPost(url, data);
}

export async function createConfirm(taskId: string) {
  const url = HOST + `/rest/task/${taskId}/confirm-creation`;
  return axiosPost(url, {});
}
export async function getSingleTask(taskId: string) {
  const url = HOST + `/rest/task/${taskId}`;
  return axiosGet(url);
}

export async function updatePpoState(taskId: string, data: any) {
  const url = HOST + `/rest/task/ppo/update-state/${taskId}`;
  return axiosPost(url, data);
}
export async function nextPpoState(taskId: string) {
  const url = HOST + `/rest/task/ppo/next-odpu-stage/${taskId}`;
  return axiosPut(url, {});
}
export async function getNextPpoState(taskId: string) {
  const url = HOST + `/rest/task/ppo/next-odpu-stage/${taskId}`;
  return axiosGet(url);
}
export async function returnTaskBack(data: ReturnTaskBackDto) {
  const url = HOST + `/rest/task/return`;
  return axiosPut(url, data);
}
export async function sendAllCipher(data: SendAllCipher) {
  const url = HOST + `/rest/task/cipher`;
  return axiosPost(url, data);
}
export async function findKidsAndParents(taskId?: string): Promise<AxiosResponse<Siblings, any>> {
  const url = HOST + `/rest/task/${taskId}/connection`;
  return axiosGet(url);
}

export async function getActDateHistory(
  taskId?: string
): Promise<AxiosResponse<ActDateHistoryResponse[], any>> {
  const url = HOST + `/rest/task/${taskId}/act-date-history`;
  return axiosGet(url);
}

export async function editVruExpendableToolsList(data: any) {
  const url = HOST + `/rest/odpu-input-expendable`;
  return axiosPut(url, data);
}
export async function addVruExpendableTool(data: {
  ppoInputId: string;
}): Promise<AxiosResponse<PpoInputExpendabResponse, any>> {
  const url = HOST + `/rest/odpu-input-expendable`;
  return axiosPost(url, data);
}

export async function addVruExternalConnection(
  ppoInputId: string,
  data: ExtraConnectionNewRequest
): Promise<AxiosResponse<ExtraConnectionResponse, any>> {
  const url = HOST + `/rest/extra-connection/ppo-input/${ppoInputId}`;
  return axiosPost(url, data);
}
export async function deleteVruExternalConnection(
  externalConnectionId: string
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/extra-connection/${externalConnectionId}`;
  return axiosDelete(url);
}
export async function editVruExternalConnection(
  data: ExtraConnectionRequest
): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/extra-connection/${data.id}`;
  return axiosPut(url, data);
}

export async function deletePpoVruExpendableTool(ExpendableToolId: string) {
  const url = HOST + `/rest/odpu-input-expendable/${ExpendableToolId}`;
  return axiosDelete(url);
}

export async function pillarDeliveryPointToSmr(transStationId: string) {
  const url = HOST + `/rest/task/trans-station/${transStationId}`;
  return axiosPost(url, {});
}

export async function sendPpoToIsur(
  id: string
): Promise<AxiosResponse<PpoInputExpendabResponse, any>> {
  const url = HOST + `/rest/transstation/${id}/send-to-isur`;
  return axiosPost(url, {});
}

export async function createTask(
  id: string,
  data: {
    contractId: string;
    regionalElectricNetworkId: string;
    transStationName: string;
    contractorId: string;
    type?: string;
    longitude?: number;
    latitude?: number;
    networkEquipmentTypeID?: string;
  }
): Promise<AxiosResponse<PpoInputExpendabResponse, any>> {
  const url = HOST + `/rest/transstation/${id}/create-task`;
  return axiosPost(url, data);
}

export async function findOdpuVruInput(searchTerm: string): Promise<AxiosResponse<any, any>> {
  const url = HOST + `/rest/odpu/vru-input/${searchTerm}/address`;
  return axiosGet(url);
}

export async function findPillarById(pillarId: string): ApiResponse<PillarResponse> {
  const url = HOST + `/rest/pillar/${pillarId}`;
  return axiosGet(url);
}

export async function savePillarById(
  pillarId: string,
  data: PillarResponse
): ApiResponse<PillarResponse> {
  const url = HOST + `/rest/pillar/${pillarId}`;
  return axiosPut(url, data);
}
export async function getTKOStausByIdTask(contractId: string): ApiResponse<PillarResponse> {
  const url = HOST + `/rest/catalog/installation-call-tko-status/${contractId}`;
  return axiosGet(url);
}
export async function getPresentEqInfo(id: string): ApiResponse<ExistingEquipmentData> {
  const url = HOST + `/rest/present-eq-info/task/${id}`;
  return axiosGet(url);
}
export async function updatePresentEqInfo(
  id: string,
  data: any,
  existingEquipmentUrl: string
): ApiResponse<ExistingEquipmentData> {
  const url = HOST + `/rest/${existingEquipmentUrl}/${id}/present-eq-info`;
  return axiosPut(url, data);
}
export async function getPresentEqInfoVru(id: string): ApiResponse<ExistingEquipmentData> {
  const url = HOST + `/rest/present-eq-info/vru-input/${id}`;
  return axiosGet(url);
}
