import React, { useRef, useEffect, useState, useMemo } from 'react';
import * as d3 from 'd3';
import { StatisticInvestProgramItem, Tooltip } from './types';
import { createLabels, createTableShowInfo, filterShowInfo } from './utils';
import shallow from 'zustand/shallow';
import { useInvestStatisticStore } from './store';
import BudgetStatisticDoughnutForm from './BudgetStatisticDoughnutForm';
import { ChartWrapper, InvestStatisticTitle } from './InvestStatistic.styled';

const BudgetStatisticDoughnut = () => {
  const [withNartis, setWithNartis] = useState(false);

  const { showInfo, setActiveIndex, equipmentsData } = useInvestStatisticStore(
    (state) => ({
      showInfo: state.showInfo,
      setActiveIndex: state.setActiveIndex,
      equipmentsData: state.equipmentsData,
    }),
    shallow
  );

  const svgRef = useRef<SVGSVGElement | null>(null);
  const [tooltip, setTooltip] = useState<Tooltip>({
    display: 'none',
    tooltipData: null,
    top: 0,
    left: 0,
  });

  const backgroundColors = ['#547483', '#344851'];
  useEffect(() => {
    // FIXME:вынести
    const normalizeData = (data: StatisticInvestProgramItem[], minPercentage = 5) => {
      const total = data.reduce((sum, item) => sum + item.count, 0);
      const minValue = (minPercentage / 100) * total;

      const updatedData = data.map((item) => ({
        ...item,
        // Модифицируем значение, для учета "минимальной" ширины сегмента
        count: item.count < minValue ? minValue : item.count,
        // Используем исходное значение для отрисовки  в тултипе
        totalCount: item.count,
      }));

      const adjustedTotal = updatedData.reduce((sum, item) => sum + item.count, 0);
      const scale = total / adjustedTotal;

      // Масштабируем значения обратно к исходной сумме
      return updatedData.map((item) => ({
        ...item,
        count: Math.round(item.count * scale),
      }));
    };

    d3.select(svgRef.current).selectAll('*').remove();

    const width = 1500;
    const height = 1000;
    const padding = 200;
    const radius = Math.min(width, height) / 2 - 10 - padding;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const defs = svg.append('defs');
    defs
      .append('filter')
      .attr('id', 'drop-shadow')
      .append('feDropShadow')
      .attr('dx', 3)
      .attr('dy', 3)
      .attr('stdDeviation', 2)
      .attr('flood-color', 'black');

    const pie = d3
      .pie<StatisticInvestProgramItem>()
      .value((d) => d.count)
      .padAngle(0.02)
      .sort(null);

    const arc = d3
      .arc<d3.PieArcDatum<StatisticInvestProgramItem>>()
      .innerRadius(radius * 0.4)
      .outerRadius(radius * 1)
      .cornerRadius(5);

    const outerArc = d3
      .arc<d3.PieArcDatum<StatisticInvestProgramItem>>()
      .innerRadius(radius * 1.2)
      .outerRadius(radius * 1.2);

    const label = d3
      .arc<d3.PieArcDatum<StatisticInvestProgramItem>>()
      .outerRadius(radius - 10)
      .innerRadius(radius - 10);

    const normalizedData = normalizeData(equipmentsData, 2);

    const arcs = pie(normalizedData);
    // FIXME: на случай отката
    // const arcs = pie(equipmentsData);

    const TOOLTIP_POSITION_GAP = 15;
    const getMouseCordsWithGap = (event: any) => ({
      top: event.clientY + TOOLTIP_POSITION_GAP,
      left: event.clientX + TOOLTIP_POSITION_GAP,
    });

    svg
      .selectAll('path.sector')
      .data(arcs)
      .enter()
      .append('path')
      .attr('class', (d, i) => `sector sector-${i}`)
      .attr('d', arc)
      .attr('fill', '#87BBD3')
      .each(function (d, i) {
        const startAngle = d.startAngle + 0.02;
        const endAngle = d.endAngle - 0.02;

        // FIXME: ХАРДКОД фильтрации
        const segments = d.data.segments.filter(
          (it) => d.data?.showInfo?.default && it !== d.data?.showInfo?.default[1]?.amount
        );

        segments.forEach((element, index) => {
          const innerArc: any = d3
            .arc()
            .innerRadius(radius * (0.4 + ((segments[index] * 100) / d.data.count / 100) * 0.6))
            .outerRadius(radius * 0.4)
            .startAngle(startAngle)
            .endAngle(endAngle);

          svg
            .append('path')
            .attr('d', innerArc)
            .attr('fill', backgroundColors[index])
            .attr('filter', 'url(#drop-shadow)')
            .attr('class', () => `sector`)
            .on('mouseover', () => {
              if (!showInfo) {
                svg.selectAll(`.hide-${d.index}`).style('opacity', 0);
                setTooltip({
                  display: 'block',
                  tooltipData: d.data,
                });
              }
            })
            .on('mousemove', (event) => {
              setTooltip((prev) => ({
                ...prev,
                ...getMouseCordsWithGap(event),
              }));
            })
            .on('mouseout', () => {
              svg.selectAll(`.hide-${d.index}`).style('opacity', 1);
              setTooltip({ display: 'none', tooltipData: null, top: 0, left: 0 });
            })
            .on('click', () => {
              console.log(d);
              setActiveIndex(d.index);
            });
        });
      })
      .on('mouseover', (event, d) => {
        if (!showInfo) {
          svg.selectAll(`.hide-${d.index}`).style('opacity', 0);
          setTooltip({
            display: 'block',
            tooltipData: d.data,
          });
        }
      })
      .on('mousemove', (event) => {
        setTooltip((prev) => ({
          ...prev,
          ...getMouseCordsWithGap(event),
        }));
      })
      .on('mouseout', (event, d) => {
        svg.selectAll(`.hide-${d.index}`).style('opacity', 1);
        setTooltip({ display: 'none', tooltipData: null, top: 0, left: 0 });
      })
      .on('click', (event, d) => {
        console.log(d);
        setActiveIndex(d.index);
      });

    let shiftAmount = 0;

    arcs.forEach((d) => {
      const percent = ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100;
      if (percent < 10 && percent) {
        shiftAmount += showInfo ? 35 : 13;
      }
    });

    // Линии к лейблам сегментов
    svg
      .selectAll('line')
      .data(arcs)
      .enter()
      .append('polyline')
      .attr('stroke', '#096FB7')
      .style('fill', 'none')
      .attr('stroke-width', 1)
      .attr('class', (d, i) => `hide-${i} hiddable`)
      .attr('points', function (d, i) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;

        const posA = label.centroid(d);
        const posB = outerArc.centroid(d);
        posB[0] = radius * (showInfo ? 1.8 : 1.45) * (midangle < Math.PI ? 1 : -1);
        const posC = outerArc.centroid(d);

        const percent = ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100;

        if (percent < 50) {
          posC[1] += shiftAmount * i;
          posB[1] += shiftAmount * i;
        }

        svg
          .append('circle')
          .attr('cx', posA[0])
          .attr('cy', posA[1])
          .attr('class', () => `hide-${i} hiddable`)
          .attr('r', 3)
          .attr('fill', '#096FB7');

        return `${posA[0]},${posA[1]} ${posC[0]},${posB[1]} ${posB[0]},${posB[1]}`;
      });

    // Лейблы сегментов
    svg
      .selectAll('allLabels')
      .data(arcs)
      .enter()
      .each(function (d, i) {
        if (showInfo) {
          createTableShowInfo(this, d, i, outerArc, radius, shiftAmount, withNartis);
        } else {
          createLabels(this, d, i, outerArc, radius, shiftAmount);
        }
      });
  }, [showInfo, equipmentsData, withNartis]);

  const filteredShowInfo = useMemo(
    () => filterShowInfo(tooltip?.tooltipData?.showInfo, withNartis),
    [tooltip?.tooltipData?.showInfo, withNartis]
  );

  return (
    <>
      <InvestStatisticTitle>ПЛАН СБЫТЫ 2024</InvestStatisticTitle>

      <ChartWrapper>
        <div>
          <BudgetStatisticDoughnutForm
            withNartis={withNartis}
            setWithNartis={setWithNartis}
          />
          <svg ref={svgRef}></svg>
          <div
            style={{
              visibility: tooltip.display === 'block' ? 'visible' : 'hidden',
              opacity: tooltip.display === 'block' ? 1 : 0,
              position: 'fixed',
              top: tooltip.top,
              left: tooltip.left,
              transition: 'opacity 0.3s ease',
              pointerEvents: 'none',
            }}
          >
            <div className='doughnut-tooltip-header'>
              <div>{tooltip?.tooltipData?.title}</div>
              <div>{tooltip?.tooltipData?.totalCount}</div>
            </div>
            <div className='doughnut-tooltip-body'>
              <table>
                <tbody className='tooltip-table-body'>
                  {filteredShowInfo &&
                    typeof filteredShowInfo === 'object' &&
                    Object.entries(filteredShowInfo).map(([key, items], index) => {
                      return (
                        <React.Fragment key={key}>
                          {index !== 0 && (
                            <tr>
                              <td style={{ height: '10px' }} />
                            </tr>
                          )}
                          {items?.map((item) => (
                            <React.Fragment key={item.title}>
                              <tr>
                                <td>{item.title}</td>
                                <td>{item.percentage}%</td>
                                <td>{item?.amount}</td>
                                <td>ШТ</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ChartWrapper>
    </>
  );
};

export default BudgetStatisticDoughnut;
