import React, { useEffect, useState } from 'react';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { FormProvider, useForm } from 'react-hook-form';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { EQUIPMENT_DATA, FILTERS_LABELS } from './utils';
import { ShowInfoControllLabel, WrapperFilters } from './InvestStatistic.styled';
import { Switch } from '@mui/material';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { useSaveFilterButton } from '@/components/filter/FilterButtons/utils';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { GetInvestStatisticByFilter } from '@/services/InvestStatisticService';
import { useInvestStatisticStore } from './store';
import shallow from 'zustand/shallow';

interface BudgetStatisticDoughnutFormProps {
  withNartis?: boolean;
  setWithNartis: (value: boolean) => void;
}

const BudgetStatisticDoughnutForm = ({
  withNartis,
  setWithNartis,
}: BudgetStatisticDoughnutFormProps) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);

  const energyCompany = getCatalogMapWithErr('energyCompany');

  // const energyCompanyOptions = useMemo(
  //   () => taskMapToArray(getCatalogMapWithErr('energyCompany')),
  //   [energyCompany]
  // );

  const energyCompanyOptions = [
    { value: 'c0caeb59-e060-eb31-240d-7b82daa12bf7', label: 'МосЭнергоСбыт' },
  ];

  // const contractListResponse = useAllContractQuery();
  const contractListResponse = [
    { value: '1bc81a82-9c91-80fe-9867-ddc38d7b291f', label: 'ИСУ МОСКВА 2024' },
    {
      value: '223cc825-f997-eafa-c3ca-778d1648cc29',
      label: 'ИСУ Опорная сеть Московская область 2024',
    },
    { value: '2aeb8e2f-aa17-b7cc-e809-1113df1f54ba', label: 'ИСУ МО 2024' },
    { value: '836c1e83-6231-0a0f-616a-d728f4e10f0e', label: 'ОДПУ Москва 2024' },
    { value: 'b03cc7ed-c3b6-c740-6554-62e09c95f13c', label: 'ОДПУ МО 2024' },
    { value: 'f7e1e13f-4bfe-9843-6acf-5de9bd12f224', label: 'ИСУ Опорная сеть Москва 2024' },
  ];

  const [selectedEnergyCompany, setSelectedEnergyCompany] = useState<SelectFilterOptType[]>([]);
  const {
    showInfo,
    selectedContract,
    setSelectedContract,
    setEquipmentsData,
    setShowInfo,
    setActiveIndex,
    setSelectedColumn,
  } = useInvestStatisticStore(
    (state) => ({
      showInfo: state.showInfo,
      selectedContract: state.selectedContract,
      setSelectedContract: state.setSelectedContract,
      setEquipmentsData: state.setEquipmentsData,
      setShowInfo: state.setShowInfo,
      setActiveIndex: state.setActiveIndex,
      setSelectedColumn: state.setSelectedColumn,
    }),
    shallow
  );

  const onResetClick = () => {
    setSelectedContract([]);
    setSelectedEnergyCompany([]);
  };

  const detailedInfo = generateDetailedInfo(
    [selectedContract, FILTERS_LABELS.contract],
    [selectedEnergyCompany, FILTERS_LABELS.energyCompany]
  );

  const onSubmit = () => {
    // setEquipmentsData(EQUIPMENT_DATA);
    GetInvestStatisticByFilter({
      contractIds: getDataFromFilter(selectedContract, 'value'),
      energyCompanyId: getDataFromFilter(selectedEnergyCompany, 'value'),
    }).then(({ data }) => {
      setEquipmentsData(data);
      setActiveIndex(null);
      setSelectedColumn(null);
    });
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const SAVE_FILTER_KEY = 'InvestStatistic';

  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[]>(filterKey);
    savedFilters.selectedContract && setSelectedContract(savedFilters.selectedContract);
    savedFilters.selectedEnergyCompany &&
      setSelectedEnergyCompany(savedFilters.selectedEnergyCompany);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ position: 'relative' }}
      >
        <WrapperFilters>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <MultiSelect
              label={FILTERS_LABELS.contract}
              options={contractListResponse}
              value={selectedContract}
              disabled={selectedEnergyCompany.length !== 0}
              onChange={(value: SelectFilterOptType[]) => setSelectedContract(value)}
            />
            <MultiSelect
              label={FILTERS_LABELS.energyCompany}
              options={energyCompanyOptions}
              value={selectedEnergyCompany}
              disabled={selectedContract.length !== 0}
              onChange={(value: SelectFilterOptType[]) => setSelectedEnergyCompany(value)}
            />
            <FilterButtons
              info={detailedInfo}
              onSearchClick={() => handleSubmit(onSubmit)}
              onCloseClick={onResetClick}
              saveFilter={{
                filterType: SAVE_FILTER_KEY,
                infoToSave: { selectedContract, selectedEnergyCompany },
                selectCallback: onSelectSavedFilter,
              }}
            />
            <ShowInfoControllLabel
              control={
                <Switch
                  checked={showInfo}
                  onChange={(e) => setShowInfo(e.target.checked)}
                />
              }
              label={'Показать инфо'}
            />
          </div>
          <div>
            <ShowInfoControllLabel
              control={
                <Switch
                  checked={withNartis}
                  onChange={(e) => setWithNartis(e.target.checked)}
                />
              }
              label={'установлено пу нартис'}
            />
          </div>
        </WrapperFilters>
      </form>
    </FormProvider>
  );
};

export default BudgetStatisticDoughnutForm;
