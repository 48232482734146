import { createStore } from '@/utils/createStore';

interface Status {
  editable: boolean;
  taskStatus?: string;
  setEditable: (editable: boolean) => void;
  setTaskStatus: (status?: string) => void;
  isDisabledAll: () => boolean;
}

export const useStatus = createStore<Status>(
  (set, get) => ({
    editable: true,
    setEditable: (editable) =>
      set(() => ({
        editable,
      })),
    setTaskStatus: (status) =>
      set(() => ({
        taskStatus: status,
      })),
    isDisabledAll: () => (get().editable ? false : get().taskStatus === 'COMPLETED'),
  }),
  'Status'
);
