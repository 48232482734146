import { ExistingEquipmentEnum } from '@/dto/taskmap/Dto';
import PresentPuEquipmen from './PresentPuEquipmen';
import PresentTransformerEquipmenOrVoltage from './PresentTransformerEquipmenOrVoltage';
import {
  PresentPu,
  PresentTransformer,
  PresentTransformerVoltage,
} from '@/dto/taskmap/task/TaskResultDto';

interface ExistingEquipmentProps {
  existingEquipment: {
    key: string;
    value: PresentPu | PresentTransformer | PresentTransformerVoltage;
  };
}

const MainWrapperExistingEquipment = ({ existingEquipment }: ExistingEquipmentProps) => {
  switch (existingEquipment.key) {
    case ExistingEquipmentEnum.presentPu:
      return <PresentPuEquipmen existingEquipment={existingEquipment} />;
    case ExistingEquipmentEnum.presentTransformer:
    case ExistingEquipmentEnum.presentTransformerVoltage:
      return <PresentTransformerEquipmenOrVoltage existingEquipment={existingEquipment} />;
    default:
      return null;
  }
};

export default MainWrapperExistingEquipment;
